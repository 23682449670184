import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { concatLatestFrom } from '@ngrx/operators'
import { MatchApi } from '@core/requests/api/match/match.api'
import { PlayerApi } from '@core/requests/api/player/player.api'
import { PdfService } from '@shared/services/pdf/pdf.service'
import { PermissionsService } from '@core/services/permissions.service'
import { TranslateService } from '@ngx-translate/core'
import { MarkersGroup, StreamState } from '@core/state/models/stream.state'

import {
  catchError,
  debounceTime,
  delay,
  filter,
  finalize,
  map,
  share,
  switchMap,
  tap,
} from 'rxjs/operators'
import { getDistinctSimplifiedLang, getSimplifiedLang } from '@core/state/selectors/user.selectors'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { parseMatch } from '@core/utils/match.utils'
import { getTeamHeader, getTeamSegmentOptions } from '@core/utils/team.utils'
import {
  GET_PLAYERS_FORMATTED_TO_FIELD_DIAGRAM,
  GET_STARTERS_AND_SUBSTITUTES_PLAYERS_FORMATTED,
  LA_LIGA_LIST_NAME,
  PLACEHOLDER_IMAGES,
  VideoType,
} from '@mediacoach-ui-library/global'
import {
  findTeamType,
  mapComparisonRequest,
  mapPlayer,
  mapPlayerSummary,
  mapTeamSquad,
  updateMatchScores,
} from '@features/matches/utils/matches.utils'
import { catchRequestError } from '@shared/operators/catch-request-error.operator'
import {
  debouncedFetchMatchData,
  exportMetricsToPDF,
  fetchMatch,
  fetchMatchData,
  fetchMatchHeatMap,
  fetchMatchMetadata,
  fetchMatchPassMatrix,
  fetchMatchPlayer,
  fetchMatchPlayerComparison,
  fetchMatchStream,
  fetchMatchTeamMetrics,
  fetchTimelineConfig,
  parseMatchMarkers,
  refreshMatchOnStateChange,
  setComparisonLoader,
  setExportMetricsToPDFLoader,
  setMatch,
  setMatchHeatMap,
  setMatchHeatMapLoader,
  setMatchLoader,
  setMatchMarkers,
  setMatchPassMatrix,
  setMatchPassMatrixLoader,
  setMatchPlayerComparison,
  setMatchPlayerMetricsLoader,
  setMatchSelectedPlayer,
  setMatchSelectedPlayerLoader,
  setMatchStream,
  setMatchTeamMetrics,
  setMatchTeamMetricsLoader,
  setMetricsLoader,
  setStreamType,
  setTimelineConfig,
  setTimelineLoader,
} from '@core/state/actions/stream-match.merged-actions'
import {
  combineLatest,
  EMPTY,
  forkJoin,
  iif,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  throwError,
} from 'rxjs'
import { EVENT_TRANSLATIONS, PERIOD_TRANSLATIONS } from '@core/constants/timeline.constants'
import { TimelineConfigDto } from '@core/models/dto/timeline.dto'
import { parseTimelineEventsToMarkers } from '@core/utils/timeline.utils'
import {
  getCurrentPlayer,
  getPlayerAggregationMode,
  getPlayerMetrics,
  parseComparedPlayers,
} from '@core/utils/player.utils'
import { AssetMatch, Match, MatchTeamData } from '@core/models/dto/match.dto'
import { changeLocation, navigateByUrl } from '@core/router/state/actions/router.actions'
import { getMatchTopic } from '@core/state/selectors/socket.selectors'
import { Topic } from '@sockets/enums/socket.enum'
import { TypedAction } from '@ngrx/store/src/models'
import {
  MCP_FEATURE_SELECTOR,
  MCP_STORE_IDENTIFIER,
} from '@core/injection-tokens/merged-store.token'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import {
  getCurrentMatchWithTimelinePeriods,
  getHasLineup,
  getMatch,
  getMatchStreamType,
} from '@core/state/selectors/stream-match.merged-selectors'
import { getExportableFileDate } from '@core/utils/date.utils'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { AnalyticsExportType } from '@core/analytics/enums/gtag-assets.enum'
import { getStaticItem, smallColorLogoPredicate } from '@core/utils/assets.utils'
import { parsePlaylistFilters } from '@features/playlist/parsers/playlist-filters.parser'
import {
  DEFAULT_ALL_FILTERS,
  DEFAULT_ERROR_TAGS,
  DEFAULT_SORT_OPTION,
} from '@features/playlist/constants/playlist.constants'
import { parsePlaylistPeriods, parseTagItem } from '@features/playlist/parsers/playlist-tags.parser'
import { getTeamPlayers, translateTagName } from '@features/playlist/utils/playlist.utils'
import { FilterItem, TagCode } from '@features/playlist/models/playlist.models'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { PlaylistFiltersModalComponent } from '@features/playlist/components/playlist-filters-modal/playlist-filters-modal.component'
import { filterByList } from '@core/utils/collection.utils'
import { McpPlayer } from '@core/models/dto/player.dto'
import { saveAs } from 'file-saver'
import { PlaylistApi } from '@core/requests/api/playlist/playlist.api'
import { PlaylistService } from '@core/services/playlist.service'
import {
  ConfirmDialogComponent,
  ConfirmDialogDataConfig,
  DialogConfig,
  DialogRef,
  DialogService,
  ToastService,
} from '@mediacoach/ui'
import {
  acceptConfirmDeleteModal,
  applyFilters,
  closeConfirmDeleteModal,
  downloadXml,
  fetchCodes,
  fetchDimensions,
  fetchFiltersData,
  fetchPlayListData,
  fetchPlayLists,
  fetchTags,
  openConfirmDeleteModal,
  openFiltersModal,
  parseTags,
  reFetchTags,
  resetFilterValuesAndActive,
  setCodes,
  setDimensions,
  setFilterConfigByType,
  setFiltersActive,
  setFilterValues,
  setFilterValuesByType,
  setIsDeletingTagItem,
  setParsedTagItems,
  setPlaylistLoader,
  setPlaylists,
  setSelectedPlaylist,
  setSelectedTagItem,
  setSortFilter,
  setTags,
  updateFilterValues,
  updateFilterValuesByType,
} from '@core/state/actions/stream-playlist.merged-actions'
import {
  selectCurrentMatchFiltersActive,
  selectCurrentMatchFilterValues,
  selectCurrentSelectedPlaylist,
  selectCurrentSortFilter,
  selectHasFiltersActive,
  selectParsedTagItems,
} from '@core/state/selectors/stream-playlist.merged-selectors'
import { pluck } from '@core/utils/object.utils'
import {
  PLAYER_DEMARCATION_GENERIC,
  PLAYER_DEMARCATION_SPECIFIC,
} from '@core/constants/player.constants'
import {
  COMMON_AGGREGATION_MODES,
  MATCH_CONTEXT_AGGREGATION_MODES,
} from '@core/constants/metric-aggregation.constants'

type UpdatedMatchAction = { updatedMatch: Match } & TypedAction<string>

@Injectable({
  providedIn: 'root',
})
export class StreamEffectsBase<U extends StreamState> {
  protected _dialogRef: DialogRef

  fetchMatch$ = createEffect(() => {
    let _fetchMetadata: boolean
    let _fetchStream: boolean

    return this._actions$.pipe(
      ofType(fetchMatch(this.identifier)),
      tap(({ fetchStream, fetchMetadata }) => {
        _fetchMetadata = fetchMetadata
        _fetchStream = fetchStream
        this._store.dispatch(setMatchLoader(this.identifier)({ matchLoader: true }))
      }),
      switchMap(({ matchId }) =>
        this._store.select(getSimplifiedLang).pipe(
          completeWhen((lang) => !!lang),
          map((lang) => ({ matchId, lang })),
        ),
      ),
      switchMap(({ lang, matchId }) =>
        this._permissions
          .getContentPermissionKeys$()
          .pipe(map((permittedKeys) => ({ permittedKeys, lang, matchId }))),
      ),
      switchMap(({ matchId, lang, permittedKeys }) =>
        this._store.select(getSeasons).pipe(
          completeWhen((seasons) => !!seasons && seasons.length > 0),
          map((seasons) => ({ matchId, lang, permittedKeys, seasons })),
        ),
      ),
      switchMap(({ matchId, lang, permittedKeys: _permittedKeys, seasons }) =>
        this._commonApi.fetchMatch(matchId).pipe(
          switchMap(({ matches }) =>
            matches.length ? of(matches[0]) : throwError(() => new Error('match not found')),
          ),
          switchMap((match) =>
            this._commonApi.fetchMatchStreamsVod(matchId).pipe(
              map(({ streamsVoD, streaming }) => ({
                match,
                streamsVoD,
                streaming,
              })),
              catchRequestError(),
            ),
          ),
          switchMap(({ match, streamsVoD, streaming }) =>
            iif(
              () => !!streaming && !!streaming?.url,
              this._commonApi.fetchStreamUrl(streaming || {}).pipe(
                map(({ url }) => ({
                  streaming: { ...streaming, finalUrl: url },
                  match,
                  streamsVoD,
                })),
                catchRequestError(),
              ),
              of({ match, streamsVoD, streaming }),
            ),
          ),
          map(({ match, streamsVoD, streaming }) => {
            const _match = parseMatch({ ...match, streamsVoD, streaming }, lang, seasons)
            return {
              ..._match,
              selectedVODAsset: {},
              teamHeader: { headers: getTeamHeader(_match) },
              teamSegmentOptions: getTeamSegmentOptions(match),
              lineup: GET_PLAYERS_FORMATTED_TO_FIELD_DIAGRAM(match.homeTeam, match.awayTeam),
              squad: { ...mapTeamSquad(match) },
              players: GET_STARTERS_AND_SUBSTITUTES_PLAYERS_FORMATTED(match),
            }
          }),
          catchError(() => {
            console.error('ERROR: Match not found', matchId)
            this._store.dispatch(navigateByUrl({ path: 'match-not-found' }))
            this._toast.show(
              {
                message: this._translate.instant('MTR_MATCH_DETAIL_ERROR_NOT_FOUND'),
              },
              {
                type: 'error',
              },
            )
            return EMPTY
          }),
        ),
      ),
      switchMap((currentMatch) => {
        const actions: Action[] = [setMatch(this.identifier)({ currentMatch })]
        if (_fetchStream) {
          actions.push(fetchMatchStream(this.identifier)({ id: currentMatch.id }))
        }

        if (_fetchMetadata) {
          actions.push(fetchMatchMetadata(this.identifier)({ matchId: currentMatch.id }))
        }
        return actions
      }),
      tap(() => {
        this._store.dispatch(setMatchLoader(this.identifier)({ matchLoader: false }))
      }),
      share(),
    )
  })

  fetchMatchTeamMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchTeamMetrics(this.identifier)),
      tap(() =>
        this._store.dispatch(
          setMatchTeamMetricsLoader(this.identifier)({ teamMetricsLoader: true }),
        ),
      ),
      switchMap(({ matchId }) =>
        this._commonApi.fetchMatchTeamMetrics(matchId).pipe(
          map((teamMetrics) => setMatchTeamMetrics(this.identifier)({ teamMetrics })),
          catchRequestError(),
          finalize(() =>
            this._store.dispatch(
              setMatchTeamMetricsLoader(this.identifier)({ teamMetricsLoader: false }),
            ),
          ),
        ),
      ),
    ),
  )

  fetchMatchPassMatrix$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPassMatrix(this.identifier)),
      tap(() =>
        this._store.dispatch(setMatchPassMatrixLoader(this.identifier)({ passMatrixLoader: true })),
      ),
      switchMap(({ matchId }) =>
        this._commonApi.fetchMatchPassMatrix(matchId).pipe(
          map((passMatrix) => setMatchPassMatrix(this.identifier)({ passMatrix })),
          catchRequestError(),
          finalize(() =>
            this._store.dispatch(
              setMatchPassMatrixLoader(this.identifier)({ passMatrixLoader: false }),
            ),
          ),
        ),
      ),
    ),
  )

  fetchHeatMap$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchHeatMap(this.identifier)),
      tap(() =>
        this._store.dispatch(setMatchHeatMapLoader(this.identifier)({ heatMapLoader: true })),
      ),
      switchMap(({ matchId }) =>
        this._commonApi.fetchMatchHeatMap(matchId).pipe(
          map((heatMap) => setMatchHeatMap(this.identifier)({ heatMap })),
          catchRequestError(),
          finalize(() =>
            this._store.dispatch(setMatchHeatMapLoader(this.identifier)({ heatMapLoader: false })),
          ),
        ),
      ),
    ),
  )

  exportMetricsToPDF$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(exportMetricsToPDF(this.identifier)),
        tap(() =>
          this._store.dispatch(setExportMetricsToPDFLoader(this.identifier)({ pdfLoader: true })),
        ),
        concatLatestFrom(() => this._store.select(getMatch(this.selector))),
        map(([{ selector, displayMode, teamType }, match]) => ({
          selector,
          displayMode,
          name: match[teamType].team.name,
        })),
        map(({ selector, displayMode, name }) => ({
          filename: `player-metrics_${name
            .replace(/\./, '')
            .replace(/\s/, '-')}_${displayMode}_${getExportableFileDate()}`,
          selector,
        })),
        map(({ filename, selector }) =>
          this._pdf
            .exportAsPDF({
              selector,
              imagePlaceholder: PLACEHOLDER_IMAGES.PLAYER,
              orientation: 'l',
              filename,
              exportType: AnalyticsExportType.Lineup,
            })
            .finally(() =>
              this._store.dispatch(
                setExportMetricsToPDFLoader(this.identifier)({
                  pdfLoader: false,
                }),
              ),
            ),
        ),
        share(),
      ),
    { dispatch: false },
  )

  fetchTimelineConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTimelineConfig(this.identifier)),
      tap(() => this._store.dispatch(setTimelineLoader(this.identifier)({ timelineLoader: true }))),
      switchMap(({ matchId }) =>
        this._commonApi.fetchTimelineConfig(matchId).pipe(catchRequestError()),
      ),
      this._i18nSynchronized(),
      switchMap((config) =>
        combineLatest([
          this._translateTimeline(EVENT_TRANSLATIONS),
          this._translateTimeline(PERIOD_TRANSLATIONS),
          this._translate.get('MTR_COMMON_MATCH_STATE_HALFTIME'),
        ]).pipe(
          map(([events, periods, defaultTranslation]) => ({
            timelineConfig: {
              ...config,
              periodTranslations: periods,
              eventTranslations: events,
            } as TimelineConfigDto,
            defaultTranslation,
          })),
        ),
      ),
      switchMap(({ timelineConfig, defaultTranslation }) => [
        setTimelineConfig(this.identifier)({ timelineConfig }),
        parseMatchMarkers(this.identifier)({
          timelineConfig,
          defaultTranslation,
        }),
      ]),
      tap(() =>
        this._store.dispatch(setTimelineLoader(this.identifier)({ timelineLoader: false })),
      ),
      share(),
    ),
  )

  parseMatchMarkers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(parseMatchMarkers(this.identifier)),
      map(({ timelineConfig, defaultTranslation }) => ({
        [VideoType.Tactical]: parseTimelineEventsToMarkers(
          timelineConfig,
          VideoType.Tactical,
          defaultTranslation,
        ),
        [VideoType.Tv]: parseTimelineEventsToMarkers(
          timelineConfig,
          VideoType.Tv,
          defaultTranslation,
        ),
        [VideoType.Panoramic]: parseTimelineEventsToMarkers(
          timelineConfig,
          VideoType.Panoramic,
          defaultTranslation,
        ),
      })),
      map((markers: MarkersGroup) => setMatchMarkers(this.identifier)({ markers })),
      share(),
    ),
  )

  fetchMatchPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPlayer(this.identifier)),
      tap(() =>
        this._store.dispatch(setMatchSelectedPlayerLoader(this.identifier)({ playerLoader: true })),
      ),
      concatLatestFrom(() => [
        this._store.select(getMatch(this.selector)),
        this._store.select(getHasLineup(this.selector)),
      ]),
      map(([{ playerId, teamType, position }, match, hasLineup]) => {
        const player = (
          match[teamType].team.squad && match[teamType].team.squad.length > 0
            ? match[teamType].team.squad
            : match[teamType].team.lineup
        ).find(({ id }) => playerId === id)
        const team = match[teamType].team
        if (player) {
          return {
            match,
            player,
            team,
            playerPosition: position || player.playerPosition,
            hasLineup,
            seasonId: match.seasonId,
            competitionId: match.competitionId,
          }
        }
      }),
      switchMap(({ match, player, team, playerPosition, seasonId, competitionId, hasLineup }) =>
        (hasLineup
          ? this._fetchMatchPlayer(match, team, player.id, playerPosition)
          : this._fetchPlayer(player, seasonId, competitionId, team, match, playerPosition)
        ).pipe(
          tap(() =>
            this._store.dispatch(
              analyticsTrackEvent({
                eventName: AnalyticsEvent.clickPlayer,
                eventParams: {
                  [AnalyticsParam.category]: AnalyticsCategory.navigation,
                  [AnalyticsParam.matchId]: match.id,
                  [AnalyticsParam.competitionId]: competitionId,
                  [AnalyticsParam.seasonId]: seasonId,
                  [AnalyticsParam.playerId]: player?.id,
                },
              }),
            ),
          ),
          map((selectedPlayer: any) =>
            setMatchSelectedPlayer(this.identifier)({
              selectedPlayer: {
                ...selectedPlayer,
                aggregationMode: getPlayerAggregationMode(hasLineup, false),
                aggregationModes: COMMON_AGGREGATION_MODES,
              },
            }),
          ),
          catchRequestError(),
        ),
      ),
      tap(() =>
        this._store.dispatch(
          setMatchSelectedPlayerLoader(this.identifier)({
            playerLoader: false,
          }),
        ),
      ),
      share(),
    ),
  )

  fetchMatchPlayerComparison$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPlayerComparison(this.identifier)),
      tap(() =>
        this._store.dispatch(setComparisonLoader(this.identifier)({ comparisonLoader: true })),
      ),
      concatLatestFrom(() => [
        this._store.select(getMatch(this.selector)),
        this._store.select(getHasLineup(this.selector)),
      ]),
      map(([{ playerA, playerB, position }, match, hasLineUp]) => [
        mapComparisonRequest(playerA, playerB, position, match, !hasLineUp),
        hasLineUp,
        match,
      ]),
      switchMap(([{ matchId, ...comparison }, hasLineup, match]: [any, boolean, Match]) =>
        (hasLineup
          ? this._commonApi.fetchMatchPlayerComparison(matchId, comparison)
          : this._playerApi.comparePlayersOverall(comparison)
        ).pipe(
          map((comparedPlayer) => {
            const data = parseComparedPlayers(
              comparedPlayer,
              comparison.playerA,
              comparison.playerB,
            )
            return {
              ...data,
              playerA: {
                ...data.playerA,
                teamType: findTeamType(data.playerA.teamId, match),
              },
              playerB: {
                ...data.playerB,
                teamType: findTeamType(data.playerB.teamId, match),
              },
            }
          }),
          map((comparisonResult) => {
            const summary = mapPlayerSummary(comparisonResult)
            const currentPlayer = getCurrentPlayer(comparisonResult, comparison.playerPosition)
            return {
              ...comparisonResult,
              ...currentPlayer,
              ...summary,
              ...getPlayerMetrics(
                summary,
                hasLineup,
                currentPlayer,
                getStaticItem(match.competition?.statics as any, smallColorLogoPredicate),
              ),
              aggregationMode: getPlayerAggregationMode(hasLineup, true),
              aggregationModes: MATCH_CONTEXT_AGGREGATION_MODES,
            }
          }),
          tap(() =>
            this._store.dispatch(
              analyticsTrackEvent({
                eventName: AnalyticsEvent.clickPlayer,
                eventParams: {
                  [AnalyticsParam.category]: AnalyticsCategory.navigation,
                  [AnalyticsParam.vsTeamIdA]: comparison?.playerA?.teamId,
                  [AnalyticsParam.vsPlayerIdA]: comparison?.playerA?.id,
                  [AnalyticsParam.vsTeamIdB]: comparison?.playerB?.teamId,
                  [AnalyticsParam.vsPlayerIdB]: comparison?.playerB?.id,
                  [AnalyticsParam.position]: comparison?.playerPosition,
                },
              }),
            ),
          ),
          map((data) => setMatchPlayerComparison(this.identifier)({ comparison: data })),
          catchRequestError(),
        ),
      ),
      tap(() =>
        this._store.dispatch(setComparisonLoader(this.identifier)({ comparisonLoader: false })),
      ),
      share(),
    ),
  )

  debounceFetch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(debouncedFetchMatchData(this.identifier)),
      tap(({ matchId }) => {
        this._store.dispatch(
          setMatchPlayerMetricsLoader(this.identifier)({
            playerMetricsLoader: true,
          }),
        )
        this._store.dispatch(setMatchLoader(this.identifier)({ matchLoader: true }))
        this._store.dispatch(setTimelineLoader(this.identifier)({ timelineLoader: true }))
        this._store.dispatch(setMetricsLoader(this.identifier)({ metricsLoader: true }))
        this._store.dispatch(setMatchStream(this.identifier)({ stream: null }))
        this._store.dispatch(changeLocation({ path: `/match-detail/${matchId}` }))
        this._store.dispatch(
          setStreamType(this.identifier)({
            streamType: { videoType: VideoType.Tactical, id: 'tac' },
          }),
        )
      }),
      delay(400),
      switchMap(({ matchId }) => [
        fetchMatchData(this.identifier)({ matchId }),
        fetchMatchStream(this.identifier)({ id: matchId }),
      ]),
      share(),
    ),
  )

  refreshOnMatchChange$ = createEffect(() =>
    this._actions$.pipe(
      ofType(refreshMatchOnStateChange(this.identifier)),
      map(({ matchId }) => fetchMatchData(this.identifier)({ matchId })),
      share(),
    ),
  )

  fetchMatchStream$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchStream(this.identifier)),
      switchMap(({ id }) =>
        this._store.select(getMatchTopic(Topic.LiveMatches)).pipe(
          completeWhen((matches) => !!matches),
          map((matches) => ({ matches, id })),
        ),
      ),
      map(({ matches, id }) => (matches || []).find((m) => m.id === id)),
      filter((match) => !!match?.streaming?.url),
      switchMap((match) =>
        this._commonApi.fetchStreamUrl(match.streaming).pipe(
          map(({ url }) =>
            setMatchStream(this.identifier)({
              stream: {
                ...match,
                streaming: {
                  ...match.streaming,
                  finalUrl: url,
                },
              },
            }),
          ),
          catchRequestError(),
        ),
      ),
      share(),
    ),
  )

  /**
   * PLAYLIST
   * ***/

  fetchPlaylistData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayListData(this.identifier)),
      tap(() => this._cleanPlaylist()),
      switchMap(({ matchId }) => [fetchPlayLists(this.identifier)({ matchId })]),
    ),
  )

  fetchPlaylists$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayLists(this.identifier)),
      concatLatestFrom(() => [
        this._store.select(selectCurrentSelectedPlaylist(this.selector)),
        this._store.select(selectCurrentSortFilter(this.selector)),
      ]),
      switchMap(([{ matchId }, currentSelectedPlaylist, currentSortFilter]) =>
        this._playlistApi.getPlaylists(matchId).pipe(
          tap((playlists) => {
            if (playlists?.results?.length && !currentSelectedPlaylist) {
              this._store.dispatch(
                setSelectedPlaylist(this.identifier)({
                  matchId,
                  selectedPlaylist: playlists?.results[0],
                }),
              )
            }
            if (!currentSortFilter) {
              this._store.dispatch(
                setSortFilter(this.identifier)({
                  matchId,
                  sortFilter: DEFAULT_SORT_OPTION,
                }),
              )
            }
            this._playlistLoaderFalsy()
          }),
          map((playlists) => ({ playlists, matchId })),
          catchRequestError(),
        ),
      ),
      switchMap(({ playlists, matchId }) => [
        setPlaylists(this.identifier)({ playlists }),
        fetchTags(this.identifier)({ matchId }),
      ]),
      catchError(() => {
        this._playlistLoaderFalsy()
        return EMPTY
      }),
    ),
  )

  fetchFiltersData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchFiltersData(this.identifier)),
      concatLatestFrom(() => this._store.select(selectCurrentSelectedPlaylist(this.selector))),
      switchMap(([{ matchId }, selectedPlaylist]) => [
        fetchCodes(this.identifier)({
          matchId,
          playlistId: selectedPlaylist?.id,
        }),
        fetchDimensions(this.identifier)({
          matchId,
          playlistId: selectedPlaylist?.id,
        }),
      ]),
    ),
  )

  fetchTags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTags(this.identifier)),
      concatLatestFrom(() => [
        this._store.select(selectCurrentMatchFiltersActive(this.selector)),
        this._store.select(selectCurrentSelectedPlaylist(this.selector)),
        this._store.select(selectCurrentSortFilter(this.selector)),
      ]),
      filter(([{ matchId }, _, selectedPlaylist]) => !!matchId && !!selectedPlaylist?.id),
      tap(() => {
        this._store.dispatch(setParsedTagItems(this.identifier)({ parsedTagItems: undefined }))
        this._store.dispatch(setPlaylistLoader(this.identifier)({ playlistLoader: true }))
      }),
      map(([{ matchId }, filters, selectedPlaylist, sortFilter]) => ({
        filters: parsePlaylistFilters(filters || {}, sortFilter),
        matchId,
        selectedPlaylist,
      })),
      switchMap(({ matchId, filters, selectedPlaylist }) =>
        this._playlistApi
          .getPlaylistTags(selectedPlaylist.id, matchId, filters)
          .pipe(catchRequestError()),
      ),
      switchMap((tags) => [
        setTags(this.identifier)({ tags }),
        parseTags(this.identifier)({ results: tags?.results }),
      ]),
      catchError(() => {
        this._playlistLoaderFalsy()
        this._toast.show(
          {
            message: this._translate.instant('MTR_PLAYLIST_GET_TAGS_ERROR'),
          },
          {
            type: 'error',
          },
        )
        this._store.dispatch(setTags(this.identifier)({ tags: DEFAULT_ERROR_TAGS }))
        return EMPTY
      }),
    ),
  )

  parseTags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(parseTags(this.identifier)),
      debounceTime(600),
      switchMap(({ results }) =>
        this._store.pipe(
          getCurrentMatchWithTimelinePeriods(this.selector),
          completeWhen((data) => !!data),
          map(({ match, periods }) => ({ match, periods, tags: results })),
        ),
      ),
      concatLatestFrom(() => [
        this._store.select(getMatchStreamType(this.selector)),
        this._store.select(selectHasFiltersActive(this.selector)),
        this._store.select(selectCurrentSelectedPlaylist(this.selector)),
      ]),
      map(([{ match, periods, tags }, streamType, hasActiveFilters, selectedPlaylist]) => ({
        match,
        periods: tags?.length ? parsePlaylistPeriods(periods) : [],
        items: tags,
        translations: this._translate.translations[this._translate.currentLang],
        videoType: streamType?.videoType,
        hasActiveFilters,
        selectedPlaylist,
      })),
      map(
        ({
          match,
          periods,
          items,
          translations,
          hasActiveFilters,
          videoType,
          selectedPlaylist,
        }) => ({
          parsedTags:
            items?.map((item, index) =>
              parseTagItem(item, index, match, translations, periods, videoType, selectedPlaylist),
            ) || [],
          matchId: match.id,
          hasActiveFilters,
        }),
      ),
      map(({ parsedTags }) => setParsedTagItems(this.identifier)({ parsedTagItems: parsedTags })),
    ),
  )

  fetchDimensions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchDimensions(this.identifier)),
      switchMap(({ matchId, playlistId }) =>
        this._playlistApi.getPlaylistDimensions(playlistId, matchId).pipe(
          map((dimensions) => setDimensions(this.identifier)({ dimensions })),
          catchRequestError(),
        ),
      ),
    ),
  )

  fetchCodes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchCodes(this.identifier)),
      switchMap(({ matchId, playlistId }) =>
        this._playlistApi.getPlaylistCodes(playlistId, matchId).pipe(catchRequestError()),
      ),
      concatLatestFrom(() => this._store.select(getSimplifiedLang)),
      map(([codes, lang]) =>
        codes
          .filter((tagCode) => tagCode.name !== 'MTR_TAGGING_QUICK_TAG')
          .map((tagCode) => ({
            ...tagCode,
            localizedName: translateTagName(tagCode, this._translate.translations[lang]),
          })),
      ),
      map((codes: TagCode[]) => setCodes(this.identifier)({ codes })),
    ),
  )

  openFiltersModal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(openFiltersModal(this.identifier)),
        concatLatestFrom(() => [
          this._store.select(getMatch(this.selector)),
          this._store.select(selectCurrentMatchFilterValues(this.selector)),
        ]),
        tap(([_, match, filterValues]) => {
          this._store.dispatch(fetchFiltersData(this.identifier)({ matchId: match?.id }))
          if (!filterValues) {
            this._store.dispatch(
              updateFilterValues(this.identifier)({
                filterValues: {
                  [FilterType.All]: [DEFAULT_ALL_FILTERS[0]],
                },
              }),
            )
          }
        }),
        tap(([{ displayMode }]) => {
          this._playlistService.openFiltersDialog(PlaylistFiltersModalComponent, {
            styleClass: `mcp-playlist-filters-modal ${
              displayMode === 'modal' ? 'mcp-playlist-filters-modal--dark m-app--dark' : ''
            }`,
            header: this._translate.instant('MTR_PLAYLIST_FILTERS_MODAL_TITLE'),
            data: {
              selector: this.selector,
              identifier: this.identifier,
            },
          })
        }),
        share(),
      ),
    { dispatch: false },
  )

  openConfirmDeleteModal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(openConfirmDeleteModal(this.identifier)),
        tap(({ displayMode, itemToDelete, matchId }) => {
          const config: DialogConfig<ConfirmDialogDataConfig> = {
            styleClass: `mcp-confirm-dialog__playlist ${
              displayMode === 'modal' ? 'mcp-confirm-dialog--dark m-app--dark' : ''
            }`,
            header: this._translate.instant('MTR_PLAYLIST_DELETE_TAG_MODAL_TITLE'),
            data: {
              message: this._translate.instant('MTR_PLAYLIST_DELETE_TAG_MODAL_MESSAGE'),
              acceptButtonText: this._translate.instant('MTR_COMMON_DELETE'),
              cancelButtonText: this._translate.instant('CONTACT_FORM_BTN_CANCEL'),
              acceptFn: () =>
                this._store.dispatch(
                  acceptConfirmDeleteModal(this.identifier)({
                    itemToDelete,
                    matchId,
                  }),
                ),
              cancelFn: () => this._store.dispatch(closeConfirmDeleteModal(this.identifier)()),
            },
          }
          this._dialogRef = this._dialogService.open(ConfirmDialogComponent, config)
        }),
      ),
    { dispatch: false },
  )

  acceptConfirmDeleteModal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(acceptConfirmDeleteModal(this.identifier)),
      concatLatestFrom(() => [
        this._store.select(selectParsedTagItems(this.selector)),
        this._store.select(selectHasFiltersActive(this.selector)),
        this._store.select(selectCurrentSelectedPlaylist(this.selector)),
      ]),
      tap(() => this._dialogRef?.close()),
      switchMap(([{ itemToDelete, matchId }, parsedItems, filtersActive, selectedPlaylist]) =>
        this._playlistApi.deleteTag(selectedPlaylist.id, itemToDelete['id']).pipe(
          tap(() => this._successDeleteTag(itemToDelete, parsedItems, filtersActive)),
          map(() => matchId),
          catchError((err) => throwError(() => ({ err, matchId }) as any)),
        ),
      ),
      debounceTime(200),
      map((matchId) => fetchTags(this.identifier)({ matchId })),
      catchError(({ matchId }) => {
        this._playlistLoaderFalsy()
        this._toast.show(
          {
            message: this._translate.instant('MTR_TAGGING_TOAST_DELETE_TAG_FAILURE'),
          },
          { type: 'error' },
        )
        this._store.dispatch(fetchTags(this.identifier)({ matchId }))
        return EMPTY
      }),
    ),
  )

  closeConfirmDeleteModal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(closeConfirmDeleteModal(this.identifier)),
        tap(() => {
          this._dialogRef?.close()
        }),
      ),
    { dispatch: false },
  )

  setEventConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setCodes(this.identifier)),
      map(({ codes }) =>
        codes.map((tagCode) => ({
          id: 'codeSnapshot.name',
          value: tagCode.name,
          label: tagCode.localizedName,
          color: tagCode.color,
        })),
      ),
      map((items: FilterItem[]) =>
        setFilterConfigByType(this.identifier)({
          filterType: FilterType.Event,
          items: items.sort((a, b) => (a.label < b.label ? -1 : 1)),
        }),
      ),
    ),
  )

  updateFilterValues$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateFilterValues(this.identifier)),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(([{ filterValues }, match]) => ({
        filterValues,
        matchId: match?.id,
      })),
      map(({ filterValues, matchId }) =>
        setFilterValues(this.identifier)({ filterValues, matchId }),
      ),
      share(),
    ),
  )

  updateFilterValuesByType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateFilterValuesByType(this.identifier)),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(([{ filterType, values }, { id: matchId }]) => ({
        filterType,
        values,
        matchId,
      })),
      map(({ filterType, values, matchId }) =>
        setFilterValuesByType(this.identifier)({ filterType, values, matchId }),
      ),
      share(),
    ),
  )

  setTeamConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setDimensions(this.identifier)),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(
        ([{ dimensions }, { home, away }]) =>
          filterByList([home.team, away.team], dimensions) as MatchTeamData[],
      ),
      map((teams) =>
        teams.map((team) => ({
          id: 'dimension',
          value: team.id,
          label: team.name,
        })),
      ),
      map((items: FilterItem[]) =>
        setFilterConfigByType(this.identifier)({
          filterType: FilterType.Team,
          items,
        }),
      ),
      share(),
    ),
  )

  setPlayerConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setDimensions(this.identifier)),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(
        ([{ dimensions }, { home, away }]) =>
          filterByList(
            [...getTeamPlayers(home.team), ...getTeamPlayers(away.team)],
            dimensions,
          ) as McpPlayer[],
      ),
      map((players) =>
        players.map((player) => ({
          id: 'dimension',
          value: player.id,
          label: player.name,
        })),
      ),
      map((items: FilterItem[]) =>
        setFilterConfigByType(this.identifier)({
          filterType: FilterType.Player,
          items,
        }),
      ),
      share(),
    ),
  )

  applyFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(applyFilters(this.identifier)),
      concatLatestFrom(() => [
        this._store.select(getMatch(this.selector)),
        this._store.select(selectCurrentMatchFilterValues(this.selector)),
      ]),
      map(([_, { id: matchId }, filtersValues]) => ({
        filtersActive: filtersValues,
        matchId,
      })),
      switchMap(({ filtersActive, matchId }) => [
        setFiltersActive(this.identifier)({ matchId, filtersActive }),
        fetchTags(this.identifier)({ matchId }),
      ]),
      share(),
    ),
  )

  reFetchTags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(reFetchTags(this.identifier)),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(([_, { id: matchId }]) => fetchTags(this.identifier)({ matchId })),
      share(),
    ),
  )

  resetFilterValuesAndActive$ = createEffect(() =>
    this._actions$.pipe(
      ofType(resetFilterValuesAndActive(this.identifier)),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(([, match]) => ({ matchId: match?.id })),
      switchMap(({ matchId }) => [
        setFilterValues(this.identifier)({
          filterValues: { [FilterType.All]: [DEFAULT_ALL_FILTERS[0]] },
          matchId,
        }),
        setFiltersActive(this.identifier)({
          filtersActive: { [FilterType.All]: [DEFAULT_ALL_FILTERS[0]] },
          matchId,
        }),
      ]),
      share(),
    ),
  )

  downloadXml$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(downloadXml(this.identifier)),
        tap(() =>
          this._store.dispatch(setPlaylistLoader(this.identifier)({ playlistLoader: true })),
        ),
        concatLatestFrom(() => [
          this._store.select(getMatch(this.selector)),
          this._store.select(selectCurrentSelectedPlaylist(this.selector)),
        ]),
        switchMap(([_, { id: matchId, home, season, away, matchdayNumber }, selectedPlaylist]) =>
          this._playlistApi.exportPlaylistTags(selectedPlaylist.id, matchId).pipe(
            tap((data) =>
              this._saveXml({
                home,
                season,
                away,
                matchdayNumber,
                data,
                playlistName: selectedPlaylist.name,
              }),
            ),
          ),
        ),
        catchError(() => {
          this._playlistLoaderFalsy()
          this._toast.show(
            {
              message: this._translate.instant('MTR_PLAYLIST_DOWNLOAD_XML_ERROR'),
            },
            { type: 'error' },
          )
          return EMPTY
        }),
        share(),
      ),
    { dispatch: false },
  )

  constructor(
    protected readonly _actions$: Actions,
    protected readonly _store: Store,
    protected readonly _commonApi: MatchApi,
    protected readonly _playerApi: PlayerApi,
    protected readonly _playlistApi: PlaylistApi,
    protected readonly _playlistService: PlaylistService,
    protected readonly _dialogService: DialogService,
    protected readonly _pdf: PdfService,
    protected readonly _permissions: PermissionsService,
    protected readonly _translate: TranslateService,
    private readonly _toast: ToastService,
    @Inject(MCP_STORE_IDENTIFIER) protected readonly identifier: MergedTokens,
    @Inject(MCP_FEATURE_SELECTOR)
    protected readonly selector: MemoizedSelector<object, U, DefaultProjectorFn<U>>,
  ) {}

  protected _mapTranslationKeys(keys: any) {
    return Object.keys(keys).map((key) => keys[key])
  }

  protected _mapTranslation<T>(translation: any, keys: any): T {
    return Object.keys(keys).reduce(
      (obj, eventKey) => ({ ...obj, [eventKey]: translation[keys[eventKey]] }),
      {} as T,
    )
  }

  protected _translateTimeline<T>(keys: any): Observable<T> {
    return this._translate
      .stream([...this._mapTranslationKeys(keys)])
      .pipe(map((translation) => this._mapTranslation<T>(translation, keys)))
  }

  protected _fetchMatchPlayer(
    match: Match,
    team: MatchTeamData,
    playerId: string,
    playerPosition: string,
  ): Observable<any> {
    const _matchPlayer = team.lineup.find((item) => item.id === playerId)
    const positions = pluck(_matchPlayer, PLAYER_DEMARCATION_GENERIC, PLAYER_DEMARCATION_SPECIFIC)
    return this._commonApi.fetchMatchPlayer(match.id, team.id, playerId, playerPosition).pipe(
      map((summary) => ({
        player: mapPlayer(summary, team, match, playerPosition),
        data: mapPlayerSummary(summary),
      })),
      concatLatestFrom(() => this._store.select(getHasLineup(this.selector))),
      map(([{ player, data }, lineup]) => ({
        ...data,
        ...player,
        ...positions,
        ...getPlayerMetrics(
          data,
          lineup,
          player,
          getStaticItem(match.competition?.statics as any, smallColorLogoPredicate),
        ),
      })),
    )
  }

  protected _fetchPlayer(
    player: any,
    seasonId: string,
    competitionId: string,
    team: any,
    match: any,
    playerPosition: string,
  ): Observable<any> {
    return forkJoin([
      this._playerApi.fetchPlayer(player.id, seasonId, competitionId, team.id),
      this._playerApi.fetchPlayerMetrics(
        seasonId,
        competitionId,
        team.id,
        player.id,
        playerPosition,
      ),
    ]).pipe(
      map(([_player, _metrics]) => {
        const currentPlayer = mapPlayer(_player, team, match, playerPosition)
        const summary = mapPlayerSummary(currentPlayer, playerPosition)

        return {
          ..._player,
          ...summary,
          ...getPlayerMetrics(
            { ...summary, ..._metrics },
            false,
            currentPlayer,
            getStaticItem(match.competition?.statics as any, smallColorLogoPredicate),
            LA_LIGA_LIST_NAME,
          ),
          playerId: _player.id,
        }
      }),
    )
  }

  protected _i18nSynchronized<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) =>
      source.pipe(
        switchMap((resolvedSource: T) =>
          this._store.pipe(
            getDistinctSimplifiedLang(),
            map((lang) => ({ resolvedSource, lang })),
          ),
        ),
        switchMap(({ resolvedSource, lang }) =>
          lang === this._translate.currentLang
            ? of(resolvedSource)
            : this._translate.onLangChange.asObservable().pipe(
                filter((l) => l.lang === lang),
                map(() => resolvedSource),
              ),
        ),
      )
  }

  protected _updateWithSocketCallback(): OperatorFunction<UpdatedMatchAction, AssetMatch> {
    return (source: Observable<UpdatedMatchAction>): Observable<AssetMatch> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(getMatch(this.selector))),
        filter(
          ([{ updatedMatch }, match]) =>
            !!updatedMatch &&
            !!match &&
            (updatedMatch.state?.minuteDescription !== match.state?.minuteDescription ||
              updatedMatch.home.score.standardTimeScore !== match.home.score.standardTimeScore ||
              updatedMatch.away.score.standardTimeScore !== match.away.score.standardTimeScore),
        ),
        map(([{ updatedMatch }, match]) => updateMatchScores(match, updatedMatch) as AssetMatch),
        share(),
      )
  }

  /**
   * PLAYLIST
   * **/

  protected _playlistLoaderFalsy() {
    this._store.dispatch(setPlaylistLoader(this.identifier)({ playlistLoader: false }))
  }

  protected _successDeleteTag(itemToDelete: any, parsedItems: any[], filtersActive: any) {
    const allItems = [...parsedItems]
    let currentIndex = itemToDelete['idx']
    allItems.splice(currentIndex, 1)
    if (currentIndex > allItems.length - 1) {
      currentIndex = currentIndex - 1
    }
    const iText = String(currentIndex + 1)
    const indexText = iText.length === 1 ? `0${iText}` : iText
    const selectedTagItem = {
      ...allItems[currentIndex],
      index: indexText,
      idx: currentIndex,
    }
    if (!allItems.length && filtersActive) {
      this._store.dispatch(resetFilterValuesAndActive(this.identifier)())
    } else {
      this._setSelectedTagItem(selectedTagItem)
      this._store.dispatch(setIsDeletingTagItem(this.identifier)({ isDeletingTagItem: true }))
    }
    this._toast.show(
      {
        message: this._translate.instant('MTR_TAGGING_TOAST_DELETE_TAG_SUCCESS'),
      },
      { duration: 3000 },
    )
  }

  private _setSelectedTagItem(selectedTagItem: any) {
    if (selectedTagItem.idx === -1) {
      this._store.dispatch(setSelectedTagItem(this.identifier)({ selectedTagItem: null }))
    } else {
      this._store.dispatch(setSelectedTagItem(this.identifier)({ selectedTagItem }))
    }
  }

  private _cleanPlaylist() {
    this._store.dispatch(setPlaylistLoader(this.identifier)({ playlistLoader: true }))
    this._store.dispatch(setPlaylists(this.identifier)({ playlists: null }))
    this._store.dispatch(setTags(this.identifier)({ tags: null }))
    this._store.dispatch(setParsedTagItems(this.identifier)({ parsedTagItems: null }))
    this._store.dispatch(setSelectedTagItem(this.identifier)({ selectedTagItem: null }))
  }

  protected _saveXml({ home, season, away, matchdayNumber, data, playlistName }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const xmlBlob = new Blob([data], { type: 'application/xml' })
      const seasonName = season.name.replace(/ /g, '')
      const playlistNameParsed = playlistName.replace(/ /g, '_')
      const fileName = `${seasonName}_J${matchdayNumber}_${home.team.abbreviation}_${away.team.abbreviation}_Playlist_${playlistNameParsed}.xml`
      saveAs(xmlBlob, fileName)
      this._toast.show(
        {
          message: this._translate.instant('MTR_PLAYLIST_DOWNLOAD_XML_SUCCESS'),
        },
        { duration: 3000 },
      )
      this._playlistLoaderFalsy()
    } catch (e) {
      throw e
    }
  }
}
