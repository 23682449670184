export const pluck = <T = unknown>(object: T, ...keys: string[]): Partial<T> =>
  keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: object[key],
    }),
    {} as Partial<T>,
  )

export const cleanObject = (obj) =>
  obj != null && typeof obj === 'object' && !Array.isArray(obj)
    ? Object.keys(obj).reduce(
        (prev, key) => ({
          ...prev,
          ...(!!obj[key] &&
            obj[key] !== 'undefined' &&
            obj[key] !== undefined && { [key]: obj[key] }),
        }),
        {},
      )
    : obj

export const cloneJSON = (json: any) => JSON.parse(JSON.stringify(json))

export const exists = (item: unknown): boolean =>
  item !== undefined && item !== null && !!Object.keys(item as object).length

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })

// Merges an array of objects with same or similar property types
export const mergeSimilarObject = <T extends Record<string, unknown>>(objArr: T[]): T => {
  const objIterableKeys = [...new Set(objArr.map((obj) => Object.keys(obj)).flat())]
  return objIterableKeys.reduce(
    (obj: T, key) => ({ ...obj, [key]: [...objArr.map((o) => o[key] || []).flat()] }),
    {} as T,
  )
}
