<div class="mcp-scrollable-item-selector" mcpDragNScroll="horizontal">
  @for (item of items; track item.id) {
    <div
      mcpDragNScrollItem
      [id]="item.id"
      class="mcp-scrollable-item-selector__item"
      [class.has-divider]="item.hasDivider"
      [class.selected]="$index === selectedIdx"
      (click)="onClick(item, $index)"
    >
      <img
        class="mcp-scrollable-item-selector__img"
        draggable="false"
        tooltipPosition="bottom"
        [class.large]="large"
        [alt]="item?.metricPlayerName ?? item?.formattedName ?? item?.name"
        [mcuiTooltip]="item?.metricPlayerName ?? item?.formattedName ?? item?.name"
        [tooltipDisabled]="isMobile"
        [appDefaultImage]="placeholder"
        [srcUrl]="item?.portraitLogo"
      />
    </div>
  }
</div>
