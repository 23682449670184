import { EnvType, firstLetterToLowerCase, UserProfileType } from '@mediacoach-ui-library/global'
import packageJson from '../../package.json'
import { ReportRoute } from '@features/reports/enums/reports.enum'

/* eslint-disable @typescript-eslint/naming-convention, max-len */
const MAIN_APP_NAME = 'portal'
const MAIN_APP_API = 'portal-api'

export const VARIABLES_BY_ENVIRONMENT = {
  [EnvType.Dv]: {
    production: false,

    POWER_BI_WORKSPACE: 'b4ce5693-889b-42ed-8865-e24e98f57816',

    POWER_BI_MONITORING: {
      '2023-2024': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'd851920a-bebb-4600-b5df-32857b53f5ae',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'ca891a60-3ef9-40b3-a831-ba66bfd309f8',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '28941b75-47de-4639-ab01-5abb50e18c94',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '659bfa61-858e-4ccc-9f83-b7d15b3ed825',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'ec1a1472-e53c-4549-a02a-782598419514',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '5179c859-f5d0-4fd1-8c98-146b591cb96f',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '8f4d3eb1-74a4-4cd1-be1d-700872b87033',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: '40f63ded-7a61-454c-ab51-f7f240dec4b0',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: 'c9c729e0-8d5d-435e-b51a-796616fa22b7',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: '7da3856c-4776-454f-89ad-c871c371df5f',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
        {
          route: ReportRoute.finishing,
          idPowerBi: 'bda1ff9f-7442-4857-a352-29be4ff7990e',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teams,
          idPowerBi: '02128f96-37e9-465d-a9d2-23d29f887d74',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '31e476b2-b3f2-44f5-bfca-5914d64f739e',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '673f0d11-cd14-4e78-8450-a3465d5eff8a',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '6052d0bd-ab9f-4bf7-ac0f-67454276725e',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '05d6de6b-3066-428a-9c0f-56816fb851b0',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '4edbe22c-bcc6-40e6-af7b-722e08214bfc',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: 'c31ed84d-e279-403d-8cb5-180f236bfe5a',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: '8e7da930-132f-40c9-bdca-d22bdf897fd1',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: 'ad03ef8c-c80d-4dd5-94cd-eb3d55788c52',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teams,
          idPowerBi: '4d2159a8-e4cf-4e47-9705-37c037a12501',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'c57b3a16-36ae-4322-bd06-0e8431dacfab',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: 'ddb16757-c17f-452f-9ee1-3048610146a9',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '93f1a5cb-a335-412b-a3f9-644426ac93a5',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'a3191ddf-a22d-4540-845b-dee3c53ccc41',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '6c7e09e0-4cd3-4a3f-8a32-2e29d36dca4f',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: 'fd309c23-d196-4171-8cfa-144344e6bc92',
        },
      ],
      '2020-2021': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'e639314d-0fc2-4b86-80f6-e258fab621c3',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '987fdd0e-4c26-4681-afc8-661c75d1c1c0',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: 'e5e0fb95-9fd9-441a-b130-f5d8fb0335e9',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: 'd0aa0a9a-345d-4e6d-81ad-5b8923a1497d',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'c300e497-bbed-4239-b48c-bdb9947bb9f9',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '33e73608-08dd-4b84-8f96-0f330915bb7d',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '556c574c-bec3-4786-a6d0-31516c962545',
        },
      ],
      '2019-2020': [
        {
          route: ReportRoute.teams,
          idPowerBi: '30f8ffef-e399-4501-9e4c-045d365767f0',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '8c979d68-f6bc-4f38-bea8-0c14c42736a6',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: 'e79b6d92-dbaa-4e6c-bb6d-30d404451eba',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: 'd79fbb79-18c2-404b-89ef-256ffa972670',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'e52b213c-c067-4183-8c4a-68d80489b748',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: 'a230f355-c219-468c-ac98-7f163c6511a7',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '7cd4d315-2b44-4516-8c27-bc030d16da02',
        },
      ],
      '2018-2019': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'c45efeed-8913-4bcf-9ef2-3f409f962f76',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'fc4bea6f-5522-47f1-bab8-6cea9d490d2e',
        },
      ],
    },
    POWER_BI_METRICS_LAB: {
      '2023-2024': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: '0333f79c-03b3-4ec7-8b29-8bdc0bfcd326',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '8ccadf17-6e53-4c7d-9c2b-a11df8dfab6c',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: 'de7670c7-ad85-44ce-8ecf-ede1db03af51',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '252a99f3-98a7-47a2-ac7a-7b18905ae766',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: 'c1f89825-a1e5-4d38-906a-4d063f946296',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '74c0de7c-492d-44d6-a404-4f49594f16f0',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: '9bf3d60b-56a8-4025-ab81-1a997bd6a077',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: 'a2cdefc0-ef0c-4e16-abb8-7c498c6d37b2',
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: '229f7fe4-3b22-465d-b715-8d57df67925e',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '1648708c-dd89-4c53-a1b3-484aabdb6b19',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '53ff9082-9d36-4302-bf47-651899ded6dd',
        },
      ],
    },

    PROFILE_TYPE_BLACKLIST: [UserProfileType.LiveProUserProfile],
  },
  [EnvType.Ts]: {
    production: false,

    POWER_BI_WORKSPACE: '2b363260-7547-4c81-92fb-5336ba7c4f75',

    POWER_BI_MONITORING: {
      '2023-2024': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'e7fc4ae4-7a9f-4372-bafc-a820a2c7d28d',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'bc34ff52-4fed-485d-a81d-f963d61fd548',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '4f7d8d02-82d1-4497-af48-14add47290ec',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '4baad447-3f07-4a39-8ea1-276c261d1176',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '17a91002-b318-4b98-86d4-26e593a414b3',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: 'fe4cfcbf-6cb2-4747-a148-5737caa07a9d',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '50c6d241-a11e-42b8-8536-d31034f28570',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: '25fc8f72-2393-40dd-b94c-953d6e69aa59',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: '8cc33fec-4157-4ab6-bf9b-c67df415e1d7',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: 'ae219a7e-89f1-44c6-a779-0f630236ac61',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
        {
          route: ReportRoute.finishing,
          idPowerBi: '0af725fd-cf5e-45a0-a0ac-5c680639dd61',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'b94ec49f-2bbe-4e2f-90ba-97b6133397c6',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'b9bb50b5-374f-4573-966a-11551d7a634f',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '2989f9da-5511-43d5-8c74-09da4514195d',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: 'e2fe4748-4800-4086-aca5-3fa728163236',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'ce68ec61-a238-4dd3-8d92-65cec450c700',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '862612ce-19cf-4ec2-94ad-6c772c44d568',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '3a48b52a-3956-4fb2-bddc-ff89b5cff1dc',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: 'b8bac76a-0da2-4454-85aa-09ee903f3908',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: '93a1acb9-26ce-4fa3-84c3-15a37836fb75',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: '89b52e23-ad66-4071-a041-935058929fff',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teams,
          idPowerBi: '7b9bf1be-0b41-47cc-b104-c8465fd3a9be',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'e00ac0ce-b33b-45f6-a4d7-2dcb3cf3924b',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: 'eda78d4f-6a28-4ea3-8d10-fb35f4dd90ca',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: 'acf3308e-01ac-42c2-9d19-966ee1cfcc39',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '84498232-8630-46ed-b40d-c915a6c2b9d6',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '2f2367c8-eaf7-4fae-816e-34aa3bcf57ef',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: 'aed80147-ae0d-44d4-abdc-e46a2b9a9193',
        },
      ],
      '2020-2021': [
        {
          route: ReportRoute.teams,
          idPowerBi: '68da2548-ed01-4e63-b1f5-e645ac13aea6',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '5e08071b-42ee-4fa4-a5cf-98ae67459f3e',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: 'b9a68aa7-b11a-4b07-a13f-5747a36e5bcf',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '05062e3c-a526-4f53-ac31-806b67558969',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '7f42cf3b-1b37-48dd-a3e7-0562970d835c',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '7133b91e-9e5a-45ed-a4f4-1f8816637cf8',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: 'fd997bea-edce-4f71-908f-f09be5a19f0f',
        },
      ],
      '2019-2020': [
        {
          route: ReportRoute.teams,
          idPowerBi: '954886ad-561b-405e-96f3-3ee01a85d084',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '1612b744-0905-4053-a011-93705e23641b',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '4b74602d-9574-4bf4-a390-908a90675586',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '755a27bd-bd78-4936-84fa-f48be4e87daa',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '2f1e7928-f992-47a3-8119-73804ecc11bd',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '8b217e21-9109-4176-9574-2e04f31cb023',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '5153cafa-bc68-4e87-b6e4-e7c78d38a4ca',
        },
      ],
      '2018-2019': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'eb9cc4c4-b4ef-4a92-a264-7436d7404c48',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '206ec7c1-fc60-468f-b77b-79d72f6fbf35',
        },
      ],
    },
    POWER_BI_METRICS_LAB: {
      '2023-2024': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: 'e3a6ab27-185f-4b25-a52a-7da02a1b2572',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: 'b29cb28b-f6ee-4dc4-adf1-51c3b31c46bf',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: '655a72ed-05ee-482b-9769-4d14f7577e23',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '72705bcd-cf26-4d4b-a19b-f80bdb178073',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: 'ada9a9dd-3fb7-45fd-bad5-273d83b73bbd',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '711d8454-458e-447d-8313-606810bfa342',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: '1e0634cd-4988-42f7-8b3d-1699015cfbf2',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '6e2d59b7-d302-4f27-9d4c-3a3342b621bd',
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: '2c782063-0d27-4956-9acb-e4532fa838a1',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '3db63977-4b36-4ff5-86e1-362ab6fcd8c9',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '2a1b3c4a-1a76-48f2-bc1e-305352cd5439',
        },
      ],
    },

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-1',

    VIMEO_API_USER: '/109050529',

    PROFILE_TYPE_BLACKLIST: [UserProfileType.LiveProUserProfile],
  },
  [EnvType.Qa]: {
    production: true,

    POWER_BI_WORKSPACE: '2b363260-7547-4c81-92fb-5336ba7c4f75',

    POWER_BI_MONITORING: {
      '2023-2024': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'e7fc4ae4-7a9f-4372-bafc-a820a2c7d28d',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'bc34ff52-4fed-485d-a81d-f963d61fd548',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '4f7d8d02-82d1-4497-af48-14add47290ec',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '4baad447-3f07-4a39-8ea1-276c261d1176',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '17a91002-b318-4b98-86d4-26e593a414b3',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: 'fe4cfcbf-6cb2-4747-a148-5737caa07a9d',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '50c6d241-a11e-42b8-8536-d31034f28570',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: '25fc8f72-2393-40dd-b94c-953d6e69aa59',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: '8cc33fec-4157-4ab6-bf9b-c67df415e1d7',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: 'ae219a7e-89f1-44c6-a779-0f630236ac61',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
        {
          route: ReportRoute.finishing,
          idPowerBi: '0af725fd-cf5e-45a0-a0ac-5c680639dd61',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'b94ec49f-2bbe-4e2f-90ba-97b6133397c6',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'b9bb50b5-374f-4573-966a-11551d7a634f',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '2989f9da-5511-43d5-8c74-09da4514195d',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: 'e2fe4748-4800-4086-aca5-3fa728163236',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'ce68ec61-a238-4dd3-8d92-65cec450c700',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '862612ce-19cf-4ec2-94ad-6c772c44d568',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '3a48b52a-3956-4fb2-bddc-ff89b5cff1dc',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: 'b8bac76a-0da2-4454-85aa-09ee903f3908',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: '93a1acb9-26ce-4fa3-84c3-15a37836fb75',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: '89b52e23-ad66-4071-a041-935058929fff',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teams,
          idPowerBi: '7b9bf1be-0b41-47cc-b104-c8465fd3a9be',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'e00ac0ce-b33b-45f6-a4d7-2dcb3cf3924b',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: 'eda78d4f-6a28-4ea3-8d10-fb35f4dd90ca',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: 'acf3308e-01ac-42c2-9d19-966ee1cfcc39',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '84498232-8630-46ed-b40d-c915a6c2b9d6',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '2f2367c8-eaf7-4fae-816e-34aa3bcf57ef',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: 'aed80147-ae0d-44d4-abdc-e46a2b9a9193',
        },
      ],
      '2020-2021': [
        {
          route: ReportRoute.teams,
          idPowerBi: '68da2548-ed01-4e63-b1f5-e645ac13aea6',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '5e08071b-42ee-4fa4-a5cf-98ae67459f3e',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: 'b9a68aa7-b11a-4b07-a13f-5747a36e5bcf',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '05062e3c-a526-4f53-ac31-806b67558969',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '7f42cf3b-1b37-48dd-a3e7-0562970d835c',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '7133b91e-9e5a-45ed-a4f4-1f8816637cf8',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: 'fd997bea-edce-4f71-908f-f09be5a19f0f',
        },
      ],
      '2019-2020': [
        {
          route: ReportRoute.teams,
          idPowerBi: '954886ad-561b-405e-96f3-3ee01a85d084',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '1612b744-0905-4053-a011-93705e23641b',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '4b74602d-9574-4bf4-a390-908a90675586',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '755a27bd-bd78-4936-84fa-f48be4e87daa',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '2f1e7928-f992-47a3-8119-73804ecc11bd',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '8b217e21-9109-4176-9574-2e04f31cb023',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '5153cafa-bc68-4e87-b6e4-e7c78d38a4ca',
        },
      ],
      '2018-2019': [
        {
          route: ReportRoute.teams,
          idPowerBi: 'eb9cc4c4-b4ef-4a92-a264-7436d7404c48',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '206ec7c1-fc60-468f-b77b-79d72f6fbf35',
        },
      ],
    },
    POWER_BI_METRICS_LAB: {
      '2023-2024': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: 'e3a6ab27-185f-4b25-a52a-7da02a1b2572',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: 'b29cb28b-f6ee-4dc4-adf1-51c3b31c46bf',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: '655a72ed-05ee-482b-9769-4d14f7577e23',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '72705bcd-cf26-4d4b-a19b-f80bdb178073',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: 'ada9a9dd-3fb7-45fd-bad5-273d83b73bbd',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '711d8454-458e-447d-8313-606810bfa342',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: '1e0634cd-4988-42f7-8b3d-1699015cfbf2',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '6e2d59b7-d302-4f27-9d4c-3a3342b621bd',
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: '2c782063-0d27-4956-9acb-e4532fa838a1',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '3db63977-4b36-4ff5-86e1-362ab6fcd8c9',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: '2a1b3c4a-1a76-48f2-bc1e-305352cd5439',
        },
      ],
    },

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-1',

    VIMEO_API_USER: '/109050529',

    PROFILE_TYPE_BLACKLIST: [UserProfileType.LiveProUserProfile],
  },
  [EnvType.Pr]: {
    production: true,
    enableSmartLook: true,

    POWER_BI_WORKSPACE: '74773ca3-859c-4c1d-95a6-f4a433f4f34e',

    POWER_BI_MONITORING: {
      '2023-2024': [
        {
          route: ReportRoute.teams,
          idPowerBi: '977f08fa-f6d6-4078-9ca3-ae2586dd6f45',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '0990faf0-b617-498a-9320-142f011601a4',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '1839ed57-9367-408f-ac80-34fc2199ab17',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '819e954c-0287-4aaf-907a-f5287e48c692',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'd572c7c0-22ab-49cf-8c0c-f3bb8356cc44',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: 'd0041e62-a893-4175-8bb5-92c8b4b4f20a',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '036dba23-5653-41eb-af5a-5286aaa37864',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: '3ca59b43-08a0-4df1-a8e9-344df020dcb4',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: 'a2132b0f-314b-4c86-8801-7c7490d42742',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: 'd55449e0-476e-4290-aeb1-8203d03f7ed1',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
        {
          route: ReportRoute.finishing,
          idPowerBi: '842fb710-7bb6-4a66-91fd-a4c194fa3123',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teams,
          idPowerBi: '3fdf46fd-f744-45a6-b10b-201e8974f41b',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'f3f22c30-a936-4018-8e36-abd2b6bad756',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '54570843-ca22-4a7d-aa15-0fdc718b4bed',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '53d15031-a745-460a-bf2b-efd0447a082d',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '98c63fd3-6645-4d2e-a1ed-ec26dc4328f1',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: 'bf6597f0-e6c8-49c6-810d-c14143b48cbf',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '8a5ffddf-4667-4a17-adff-1cea67654d55',
        },
        {
          route: ReportRoute.physicalIntervals,
          idPowerBi: '42f45658-7da7-4ac6-bbd9-86513d7f916b',
        },
        {
          route: ReportRoute.referees,
          idPowerBi: '7644bb13-26b5-44ae-9b2d-c7efc787f87d',
        },
        {
          route: ReportRoute.gameInterruptions,
          idPowerBi: 'c87c47af-dd2c-4b24-af8e-8cfacd9fa8bf',
        },
        {
          route: ReportRoute.compEvolutionEffectiveTime,
          idPowerBi: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teams,
          idPowerBi: '29c3cf6c-0ccb-4c3d-a221-b20c409b2e1b',
        },
        {
          route: ReportRoute.players,
          idPowerBi: '739655ac-9c12-4b98-a2de-39df5d7fa86b',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '7ed1a48a-7fd4-404a-a3a2-85c5db867e23',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '74386df4-f919-4355-829a-823dbd796df4',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'f5a7d0fd-a8dc-4d9c-9dac-9355030c50e4',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: 'e709da97-b9f6-4a6e-bba6-24624702eceb',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '75c6f84a-6589-4905-b7da-8edb1796a2be',
        },
      ],
      '2020-2021': [
        {
          route: ReportRoute.teams,
          idPowerBi: '05cecad8-9b58-464f-b589-96c7eb601133',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'a0d8d42c-17c7-4873-b354-476d64007ff0',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '7a1e4888-e8f1-46a9-98c6-836e0920d2c0',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: '9eacd348-c7bd-46d5-9ff8-77d82486c09f',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: '8bfce2f8-dc8c-4f97-85e6-2b0fec0e1ba6',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '7888ace3-a080-4ac3-bb92-00a36c303099',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: 'b4f96731-c047-4a86-b3b9-b3d7dfc787c0',
        },
      ],
      '2019-2020': [
        {
          route: ReportRoute.teams,
          idPowerBi: '1111e986-399f-487b-acff-3564a343f48f',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'efedad0a-ac25-443c-a612-1d99f08c9b8f',
        },
        {
          route: ReportRoute.goalkeepers,
          idPowerBi: '34fd31e0-01c8-4e95-acdf-f27181342df3',
        },
        {
          route: ReportRoute.styleEfficiency,
          idPowerBi: 'd14eb622-607d-4203-a11a-dffef5ebf68b',
        },
        {
          route: ReportRoute.playerStats,
          idPowerBi: 'bba59e13-0170-4a84-bf35-9a68cbf7ec23',
        },
        {
          route: ReportRoute.accumulatedTeams,
          idPowerBi: '4f880053-4582-4e9e-99cb-e322578d1a5c',
        },
        {
          route: ReportRoute.physicalPerformance,
          idPowerBi: '6be0c54b-2842-4aab-9a7a-a7d11663bc4d',
        },
      ],
      '2018-2019': [
        {
          route: ReportRoute.teams,
          idPowerBi: '79510897-a78a-4e8d-9503-4f8a6144db0e',
        },
        {
          route: ReportRoute.players,
          idPowerBi: 'c8277b7c-fbfd-49d5-a05f-f10f1a83f388',
        },
      ],
    },
    POWER_BI_METRICS_LAB: {
      '2023-2024': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: '680ca987-10fa-4d47-80b7-87d7fa572178',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: 'a9d9d4bc-1fe9-4d71-ad52-b01313e18e63',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: '5e16818d-6c4f-4d97-b97f-eb269430044f',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: 'ef0f6704-b3b5-4c35-9a31-f9afcfb8b3de',
        },
      ],
      '2022-2023': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: '22830b2b-8a1e-485d-84d0-53e381a84277',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: '33963a4f-d6c7-464c-921c-cf2a07791db2',
        },
        {
          route: ReportRoute.playersMLPhysical,
          idPowerBi: 'ec6bcd4c-7032-4651-aaea-083fba40f008',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: 'b22b324f-cc50-4c05-998b-738f8565e962',
        },
      ],
      '2021-2022': [
        {
          route: ReportRoute.teamsMl,
          idPowerBi: '9e4e380f-2be7-4022-a130-86230fe4c6b8',
        },
        {
          route: ReportRoute.playersMl,
          idPowerBi: 'c5b89989-75a7-4c45-a8d4-320fa44ecb2c',
        },
        {
          route: ReportRoute.wcsMl,
          idPowerBi: 'ab759809-7301-46e7-81e9-7c64b2ac6a87',
        },
      ],
    },

    ALLOW_ASSET_STREAMING: false,

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113371562-1',

    VIMEO_API_USER: '/109050529',

    PROFILE_TYPE_BLACKLIST: [],

    DOWNLOAD_PRODUCTS_BLACKLIST: [],
  },
}

const GetEnvironmentInfo = (env: EnvType) => {
  const {
    COMMERCIAL_DOMAIN,
    APP_DOMAIN,
    IDENTITY_SERVER,
    API_PORTAL_URL,
    API_INTEGRATED_DOMAIN,
    API_TV_DOMAIN,
  } = GetDomainInfo(env)
  const STATICS_URL_ROOT = GetUrlByEnv('https://festatics.mediacoach.es/', env)
  const STATICS_FULL_URL = (path: string) => STATICS_URL_ROOT + firstLetterToLowerCase(path)

  const ORIGIN_DOMAIN = window.location.origin

  return {
    production: false,
    serverMocks: false,

    DEFAULT_ROUTE: '',
    API_KEY_SECURE: 'Bearer',

    APP_DOMAIN,

    ORIGIN_DOMAIN,

    STATICS_URL_ROOT,

    STATICS_URL_ASSETS: 'assets/',

    LEGAL_NOTICE_URL: (lang) =>
      STATICS_FULL_URL(`Licenser/LicenseAgreement/License-Agreement_${lang}.html`),

    DOWNLOADS_VERSION_FILE_PATH: {
      live: {
        ios: STATICS_FULL_URL('Live/iOS/updatesMCLive.txt'),
        android: STATICS_FULL_URL('Live/Android/updatesMCLive.txt'),
      },
      desktop: {
        win: STATICS_FULL_URL('Desktop/Win/Installer/updatesMCDesktop.txt'),
        mac: STATICS_FULL_URL('Desktop/MacOS/Installer/updates.json'),
      },
      mobile: {
        ios: STATICS_FULL_URL('Mobile/iOS/updatesMCMobile.txt'),
        android: STATICS_FULL_URL('Mobile/Android/updatesMCMobile.txt'),
      },
      livePro: {
        ios: STATICS_FULL_URL('livepro/iOS/updatesMCLivePRO.txt'),
      },
    },

    PRIVACY_POLICY: 'https://policies.mediacoach.es/privacy',

    RECAPTCHA_SITE_KEY: '6Ld7xZ8UAAAAABoGqReo4tz4uMkK8oj0MrMIv1HS',

    DEBOUNCE_TIME: {
      FOR_CRASHES: 10,
      NORMAL: 100,
      INPUT_CHANGES: 200,
      CURSOR_CHANGES: 1000,
      LONG: 200,
    },

    TOAST_TIME: {
      DEFAULT: 3000,
      LONG: 5000,
    },

    ALLOW_ASSET_STREAMING: true,

    I18N_LANGS: [
      {
        id: 'es',
        locale: 'es-ES',
        i18nLabel: 'COMMONS_HEADER_OP_MENU_LANG_ES',
      },
      {
        id: 'en',
        locale: 'en-GB',
        i18nLabel: 'COMMONS_HEADER_OP_MENU_LANG_EN',
      },
    ],
    I18N_DEFAULT_LANG: 'es',

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-108719301-1',

    SMART_LOOK_API_KEY: '9ccdb4ab02c65eb0b614fbace6eaf9a9755046b3',

    POWER_BI_BASE_URL: 'https://app.powerbi.com/reportEmbed',

    POWER_BI_WORKSPACE: 'b4ce5693-889b-42ed-8865-e24e98f57816',

    POWER_BI_CURRENT_SEASON: '2023-2024',

    VIMEO_API_USER: '/110927286',

    PROFILE_TYPE_BLACKLIST: [],

    DOWNLOAD_PRODUCTS_BLACKLIST: [],

    API: {
      VERSION: '1.0',
      MAIN_PATH: `${API_PORTAL_URL}/`,
      GET_URL_DOWNLOAD: `${API_PORTAL_URL}/downloads`,
      GET_POWER_BI_REPORTS: `${API_PORTAL_URL}/powerbireports`,
      GET_VIMEO_ASSETS_BY_CONNECTION: `${API_PORTAL_URL}/vimeo`,
      GET_VIMEO_ALBUMS_BY_USER: `${API_PORTAL_URL}/vimeo/albums`,
      ENABLE_PERMISSION_REQUEST: `${API_PORTAL_URL}/enablePermissionRequest`,
      ISSUES_CRUD: `${API_PORTAL_URL}/issues`,
      ISSUES_CONFIG: `${API_PORTAL_URL}/issues/config`,
      ISSUES_ATTACHMENTS: `${API_PORTAL_URL}/issues/attachments`,
      DELETE_TAG: (tagId) => `${API_PORTAL_URL}/match/tags/${tagId}`,
      GET_PLAYLISTS: `${API_PORTAL_URL}/playlists`,
      GET_PLAYLIST_TAGS: (id) => `${API_PORTAL_URL}/playlists/${id}/tags`,
      GET_PLAYLIST_DIMENSIONS: (id) => `${API_PORTAL_URL}/playlists/${id}/tags/dimensions`,
      GET_PLAYLIST_CODES: (id) => `${API_PORTAL_URL}/playlists/${id}/tags/codes`,
      DELETE_PLAYLIST_TAG: (id, tagId) => `${API_PORTAL_URL}/playlists/${id}/tags/${tagId}`,
      EXPORT_PLAYLIST_TAGS: (id) => `${API_PORTAL_URL}/playlists/${id}/tags/export`,
    },

    INTEGRATED_API: GetIntegratedServicesApi(API_INTEGRATED_DOMAIN, API_TV_DOMAIN),
    APIM_API: getApimApi(env),

    IDENTITY_SERVER: {
      AUTHORITY_URL: IDENTITY_SERVER,
      REDIRECT_URI: `${ORIGIN_DOMAIN}/auth`,
      API_LOGOUT: `${IDENTITY_SERVER}/connect/endsession`,
      POST_LOGOUT_REDIRECT_URI: COMMERCIAL_DOMAIN,
      TAG_POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
      SILENT_REDIRECT_URI: `${ORIGIN_DOMAIN}/silent`,
      CLIENT_ID: '39e03e15-7240-8199-8068-ae6c344d8161',
      ACCESS_TOKEN_EXPIRING_NOTIFICATIONTIME: 4,
      LOAD_USER_INFO: true,
      RESPONSE_TYPE: 'token id_token',
      SCOPE:
        'openid profile portal portal-backend integratedservices-api integratednotifications-api tagmanager-api presetsmanager-api',
    },
  }
}

const GetDomainInfo = (env: EnvType) => ({
  COMMERCIAL_DOMAIN: GetUrlByEnv('https://www.mediacoach.es', env),
  APP_DOMAIN: GetUrlByEnv(`https://${MAIN_APP_NAME}.mediacoach.es`, env),
  IDENTITY_SERVER: GetUrlByEnv('https://id.mediacoach.es', env),
  API_PORTAL_URL: GetUrlByEnv(`https://${MAIN_APP_API}.mediacoach.es`, env),
  API_INTEGRATED_DOMAIN: GetUrlByEnv('https://integratedservices-api.mediacoach.es', env),
  API_TV_DOMAIN: GetUrlByEnv('https://portal-ws.mediacoach.es', env),
})

const GetUrlByEnv = (domain, env) => {
  const chunks = domain.split('.')
  chunks[0] += env === EnvType.Pr ? '' : `-${env}`
  return chunks.join('.')
}

export const GetEnvironment = (env: EnvType) => ({
  appVersion: packageJson?.version,
  appBuild: '1154',
  appRelease: '26082024.0725',
  appBranch: 'feature/MCPF-3637__v9-pdf-export--CS',
  envType: env,
  ...GetEnvironmentInfo(env),
  ...VARIABLES_BY_ENVIRONMENT[env],
})

export const getApimApi = (env: EnvType) => {
  const baseUrl = GetDomainInfo(env).API_PORTAL_URL
  const playerHeader = (
    teamId: string,
    playerId: string,
    seasonId: string,
    competitionId: string,
  ) =>
    `${baseUrl}/teams/${teamId}/players/${playerId}/seasons/${seasonId}/competitions/${competitionId}`

  return {
    MAIN_PATH: baseUrl,
    TEAM_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/team`,
    TEAM_TEMPLATES: `${baseUrl}/layoutconfiguration/team/templates`,
    TEAM_HEADER: (seasonId: string, competitionId: string, teamId: string) =>
      `${baseUrl}/seasons/${seasonId}/competitions/${competitionId}/teams/${teamId}`,
    COMPETITIONS_WIDGET: `${baseUrl}/widgets/competition`,
    DEMARCATIONS: `${baseUrl}/metricdefinitions/playerPositions`,
    TEAM_METRIC_DEFINITION: `${baseUrl}/metricdefinitions/team`,
    WIDGETS: `${baseUrl}/widgets`,
    SPIDER_CHART: `${baseUrl}/widgets/spiderchart`,
    RANKING_WIDGETS: `${baseUrl}/widgets/ranking`,
    PLAYER_CAREER: `${baseUrl}/widgets/player-career`,
    GOALKEEPER_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/goalkeeper`,
    GOALKEEPER_TEMPLATES: `${baseUrl}/layoutconfiguration/goalkeeper/templates`,
    PLAYER_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/player`,
    PLAYER_TEMPLATES: `${baseUrl}/layoutconfiguration/player/templates`,
    PLAYER_HEADER: playerHeader,
    PLAYER_HEADER_METRICS: (
      teamId: string,
      playerId: string,
      seasonId: string,
      competitionId: string,
    ) => `${playerHeader(teamId, playerId, seasonId, competitionId)}/competitionsInfo`,
    GOALKEEPER_METRIC_DEFINITION: `${baseUrl}/metricdefinitions/goalkeeper`,
    PLAYER_METRIC_DEFINITION: `${baseUrl}/metricdefinitions/player`,
    SEARCH_PLAYERS: `${baseUrl}/players/search`,
    STANDINGS: (matchdayId: string) => `${baseUrl}/matchdays/${matchdayId}/standings`,
    COMPETITION_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/competition`,
    COMPETITION_HEADER: (seasonId: string, competitionId: string) =>
      `${baseUrl}/season/${seasonId}/competition/${competitionId}`,
    MATCHES_BY_MATCH_DAY: (matchDayId: string) => `${baseUrl}/matchdays/${matchDayId}/matches`,
  }
}

export const GetIntegratedServicesApi = (appDomain, websocketDomain) => {
  const apiPath = `${appDomain}/api/`

  const MATCHES = (matchId?) => `${apiPath}matches${matchId ? `/${matchId}` : ''}`

  const SEASONS = (id?) => `${apiPath}seasons${id ? `/${id}` : ''}`
  const COMPETITIONS = (seasonId, competitionId?) =>
    `${SEASONS(seasonId)}/competitions${competitionId ? `/${competitionId}` : ''}`
  const MATCHES_BY_SEASON_COMPETITION = (seasonId, competitionId) =>
    `${COMPETITIONS(seasonId, competitionId)}/matches`
  const TEAMS = (seasonId, competitionId, teamId?) =>
    `${COMPETITIONS(seasonId, competitionId)}/teams${teamId ? `/${teamId}` : ''}`
  const PLAYERS = (seasonId, competitionId, teamId, id?) =>
    `${TEAMS(seasonId, competitionId, teamId)}/players${id ? `/${id}` : ''}`
  const USER_PROFILE = `${apiPath}userprofile`
  return {
    MAIN_PATH: apiPath,
    USER_PROFILE,
    UPDATE_AVATAR: `${USER_PROFILE}/avatar`,
    SEASONS,
    SETTINGS: `${apiPath}settings`,
    TRANSLATIONS: (language) => `${apiPath}translations/${language}`,
    TEAMS,
    TEAM_OVERVIEW_MATCHES: (seasonId, teamId) =>
      `${apiPath}seasons/${seasonId}/teams/${teamId}/overviewmatches`,
    LAST_TEAM_MATCHES: (seasonId, teamId) =>
      `${apiPath}seasons/${seasonId}/teams/${teamId}/lastmatches`,
    TEAM_METRIC_RESULTS: (seasonId, competitionId, teamId) =>
      `${TEAMS(seasonId, competitionId, teamId)}/metricresults`,
    TEAMS_COMPARE: `${apiPath}teams/compare`,
    MATCHDAYS: `${apiPath}matchdays`,
    PLAYERS,
    PLAYERS_METRIC_RESULTS: (seasonId, competitionId, teamId, id) =>
      `${PLAYERS(seasonId, competitionId, teamId, id)}/metricresults`,
    PLAYERS_SEARCH: `${apiPath}players/search`,
    PLAYERS_COMPARE: `${apiPath}players/compare`,
    MATCH_PLAYERS_COMPARE: (matchId) => `${apiPath}matches/${matchId}/players/compare`,
    PLAYER_LAST_PLAYED_MATCHES: (seasonId, id) =>
      `${SEASONS(seasonId)}/players/${id}/lastplayedmatches`,
    PLAYER_LAST_PLAYED_MATCH: (seasonId, id) =>
      `${SEASONS(seasonId)}/players/${id}/lastplayedmatch`,
    STANDINGS_BY_MATCHDAY_ID: (matchDayId) => `${apiPath}matchdays/${matchDayId}/standings`,
    MATCH_PLAYER_BY_ID: (matchId, teamId, id) =>
      `${apiPath}matches/${matchId}/teams/${teamId}/players/${id}`,
    MATCHES,
    LAST_MATCHES: (matchId) => `${MATCHES(matchId)}/lastnmatches`,

    MATCH_STREAMING: (matchId) => `${MATCHES(matchId)}/streaming`,
    MATCH_PASSMATRIX: (matchId) => `${MATCHES(matchId)}/passmatrix`,
    MATCH_HEATMAP: (matchId) => `${MATCHES(matchId)}/heatmap`,
    MATCH_TEAM_METRICS: (matchId) => `${MATCHES(matchId)}/teammetricresults`,
    MATCH_PLAYER_LIST_METRICS: (matchId) => `${MATCHES(matchId)}/playerlistmetricresults`,
    MATCHES_BY_SEASON_COMPETITION,
    METRIC_DEFINITIONS: `${apiPath}metricdefinitions`,
    WEB_SOCKET: (accessToken) => `${websocketDomain}?access_token=${accessToken}`,
    FILTER_MATCHES_TIMELINE: (id) => `${apiPath}matches/${id}/streamevents?videoMetadataType=ALL`,
    MATCH_REFEREES: (matchId: string) => `${MATCHES(matchId)}/referees`,
    MATCH_KIT_SELECTOR: (matchId: string) => `${MATCHES(matchId)}/kitselector`,
    MATCH_GREEN_KEEPER: (matchId: string) => `${MATCHES(matchId)}/greenkeeper`,
    MATCH_SUNLIGHT: (matchId: string) => `${MATCHES(matchId)}/sunlight`,
    CALENDAR_COMPETITION_MATCHES: ({ seasonId, itemId, from, to }) =>
      `${MATCHES_BY_SEASON_COMPETITION(
        seasonId,
        itemId,
      )}betweendates?dateFrom=${from}&dateTo=${to}`,
    CALENDAR_TEAM_MATCHES: ({ seasonId, itemId, from, to }) =>
      `${SEASONS(seasonId)}/teams/${itemId}/matchesbetweendates?dateFrom=${from}&dateTo=${to}`,
  }
}
/* eslint-enable @typescript-eslint/naming-convention, max-len */
