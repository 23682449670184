import { createAction, props } from '@ngrx/store'
import { GridsterConfig } from 'angular-gridster2'
import { Widget, WidgetSpaceType } from '@widgets/models/widget.models'
import { SpaceTemplate } from '@core/space/models/space.models'

export const fetchSpaceLayout = createAction('[Space] fetch space layout')

export const fetchSpaceWidgets = createAction('[Space][layout] fetch space widgets')

export const setSpaceWidgetLoading = createAction(
  '[Space][layout] set space widget loading',
  props<{ widgetLoading: boolean }>(),
)
export const setSpaceLayoutConfig = createAction(
  '[Space][layout] set space layout config',
  props<{ config: GridsterConfig }>(),
)
export const setSpaceWidgets = createAction(
  '[Space][layout] set space widgets',
  props<{ widgets: Widget[] }>(),
)

export const saveSpaceWidgets = createAction(
  '[Space][layout] save space widgets',
  props<{ widgets: Widget[] }>(),
)

export const fetchSpaceTemplates = createAction('[Space][layout] fetch space templates')

export const setSpaceTemplates = createAction(
  '[Space][layout] set space templates',
  props<{ templates: SpaceTemplate[] }>(),
)

export const setSpaceMediacoachTemplates = createAction(
  '[Space][layout] set space Mediacoach templates',
  props<{ mcTemplates: SpaceTemplate[] }>(),
)

export const saveSpaceTemplate = createAction(
  '[Space][layout] save space template',
  props<{ templateName: string; widgets: Widget[] }>(),
)

export const updateSpaceTemplate = createAction(
  '[Space][layout] update space template',
  props<{ oldName: string; template: SpaceTemplate }>(),
)

export const deleteSpaceTemplate = createAction(
  '[Space][layout] delete space template2',
  props<{ templateName: string }>(),
)

export const exportWidgetToPDF = createAction(
  '[Space] export widget to PDF',
  props<{ widget: Widget; spaceType: WidgetSpaceType }>(),
)

export const exportSpaceToPDF = createAction(
  '[Space] export space to PDF',
  props<{ widgets: Widget[]; spaceType: WidgetSpaceType }>(),
)
