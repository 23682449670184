import { inject, Injectable } from '@angular/core'
import domtoimage from 'dom-to-image'
import { jsPDF } from 'jspdf'
import { getUserAgent } from '@core/utils/window.utils'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { Store } from '@ngrx/store'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsExportType } from '@core/analytics/enums/gtag-assets.enum'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'
import { PDFPayload, PDFSettings } from '@shared/services/pdf/models/pdf.models'
import { removeElements } from '@core/utils/dom.utils'

@Injectable()
export class PdfService {
  private readonly _store: Store = inject(Store)
  private readonly _preventImages: boolean = ['safari', 'firefox'].includes(getUserAgent())
  private readonly _defaultFilename = `MCPortal_${new Date().getTime()}`
  private readonly _scale: number = 2

  private _convertDomToPNG(
    element: HTMLElement,
    filename: string,
    options: PDFSettings,
    preventImages?: boolean,
  ): Promise<jsPDF> {
    return this._handleDOMImages(element, options, preventImages).then((imageData) => {
      const doc = new jsPDF(options.orientation, 'px', [options.width + 16, options.height + 16])
      doc.addImage({
        imageData,
        x: 8,
        y: 8,
        width: options.width,
        height: options.height,
        compression: 'NONE',
      })
      return doc.save(filename.toLowerCase())
    })
  }

  private _sendAnalytics(filename: string, exportType: AnalyticsExportType) {
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: AnalyticsEvent.downloadFile,
        eventParams: {
          [AnalyticsParam.category]: AnalyticsCategory.downloads,
          [AnalyticsParam.fileName]: `${filename}.pdf`,
          [AnalyticsParam.exportType]: exportType,
        },
      }),
    )
  }

  private _handleDOMImages(
    element: HTMLElement,
    options: PDFSettings,
    preventImages?: boolean,
  ): Promise<any> {
    return (
      this._preventImages || preventImages
        ? removeElements(element, 'img')
        : Promise.resolve(element)
    ).then((el: HTMLElement) => domtoimage.toPng(el, options))
  }

  private _buildPDFSettings(el: HTMLElement, payload: PDFPayload): PDFSettings {
    return {
      background: 'white',
      width: el.scrollWidth * this._scale,
      height: el.scrollHeight * this._scale,
      style: {
        ...payload.style,
        transform: 'scale(' + this._scale + ')',
        transformOrigin: 'top left',
      },
      cacheBust: true,
      imagePlaceholder: payload.imagePlaceholder ?? PLACEHOLDER_IMAGES.DEFAULT,
      orientation: payload.orientation,
    } as PDFSettings
  }

  private _getElementAndSettings(payload: PDFPayload): {
    settings: PDFSettings
    element: HTMLElement
  } {
    const _el: HTMLElement = document.querySelector(payload.selector)
    _el.classList.add('remove-scrolls')

    return {
      element: _el,
      settings: this._buildPDFSettings(_el, payload),
    }
  }

  async exportAsMultiPagePDF(pages: PDFPayload[], spaceData: any) {
    let doc: jsPDF = new jsPDF()

    try {
      // setup doc with the first page

      if (pages?.length) {
        const { settings } = this._getElementAndSettings(pages[0])
        doc = new jsPDF(settings.orientation, 'px', [settings.width + 16, settings.height + 16])
      }

      for (let i = 0; i < pages.length; i++) {
        const { element, settings } = this._getElementAndSettings(pages[i])
        try {
          const imageData = await this._handleDOMImages(element, settings)
          doc.addImage({
            imageData,
            x: 8,
            y: 8,
            width: settings.width,
            height: settings.height,
            compression: 'NONE',
          })
          if (i < pages.length - 1) {
            doc.addPage([settings.width + 16, settings.height + 16], settings.orientation)
          }
        } catch (e) {
          console.error(`[PDF Service] Unable to create PDF Page of ${pages[i].selector}`, e)
        } finally {
          element.classList.remove('remove-scrolls')
        }
      }

      return doc.save(spaceData.filename.toLowerCase())
    } catch (e) {
      console.error(`[PDF Service] Unable to export to PDF ${spaceData.spaceType} space`, e)
      return Promise.resolve()
    }
  }

  async exportAsPDF(payload: PDFPayload): Promise<jsPDF | void> {
    const { element, settings } = this._getElementAndSettings(payload)

    if (element) {
      this._sendAnalytics(payload.filename, payload.exportType)
      return this._convertDomToPNG(element, payload.filename, settings, payload.preventImages)
        .catch((e) => {
          console.error('[PDF Service] Unable to export to PDF', e)
          return Promise.resolve()
        })
        .finally(() => element.classList.remove('remove-scrolls'))
    }
    return Promise.resolve()
  }
}
