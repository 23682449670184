import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { ScrollableSelectableItem } from './models/scrollable-item-selector.models'
import { exists } from '@mediacoach/ui'
import { Subject } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DragNScrollDirective } from '@shared/directives/drag-n-scroll/drag-n-scroll.directive'
import { DragNScrollItemDirective } from '@shared/directives/drag-n-scroll/drag-n-scroll-item.directive'
import { filter, map } from 'rxjs/operators'
import { scrollIntoView } from '@core/utils/dom.utils'
import { Platform } from '@angular/cdk/platform'

@Component({
  selector: 'mcp-scrollable-item-selector',
  templateUrl: './scrollable-item-selector.component.html',
  styleUrl: './scrollable-item-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollableItemSelectorComponent {
  private _selectedIdx: number
  private _payload$$ = new Subject<void>()
  private _scrollableItems: QueryList<ElementRef>

  get isMobile(): boolean {
    return this._platform.IOS || this._platform.ANDROID
  }

  @ViewChild(DragNScrollDirective) ds: DragNScrollDirective
  @ViewChildren(DragNScrollItemDirective, { read: ElementRef })
  set scrollableItems(items: QueryList<ElementRef>) {
    this._scrollableItems = items
    this._payload$$.next()
  }

  get selectedIdx(): number {
    return this._selectedIdx
  }

  @Input() set selectedIdx(idx: number) {
    this._selectedIdx = idx
    this._payload$$.next()
  }

  @Input() loading: boolean
  @Input() items: (ScrollableSelectableItem & any)[]
  @Input() placeholder: string
  @Input() large: boolean

  @Output() selectItem: EventEmitter<ScrollableSelectableItem> = new EventEmitter()

  constructor(private readonly _platform: Platform) {
    this._payload$$
      .pipe(
        filter(() => exists(this._scrollableItems) && exists(this._selectedIdx)),
        map(() => this._scrollableItems.get(this._selectedIdx)),
        filter((item) => !!item?.nativeElement),
        takeUntilDestroyed(),
      )
      .subscribe((item) => {
        this._scrollToSelected(item.nativeElement)
      })
  }

  onClick(item: any, idx: number) {
    this.selectItem.emit(item)
    this.selectedIdx = idx
  }

  private _scrollToSelected(element: HTMLElement) {
    scrollIntoView(element, { behavior: 'auto', inline: 'center', block: 'end' })
  }
}
