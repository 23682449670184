import { getSeasonAndCompetitionById } from '../../utils/season.utils'
import {
  Widget,
  WidgetQuery,
  WidgetQueryCompare,
  WidgetSpaceType,
} from '@widgets/models/widget.models'
import { Competition, Season } from '../../models/dto/season.dto'
import { SPACE_WIDGET_ID_DOM_SELECTOR_PREFIX } from '@core/space/constants/space.constants'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'
import { PDFPayload } from '@shared/services/pdf/models/pdf.models'

export const getWidgetCompareQuery = (query: WidgetQuery): WidgetQueryCompare =>
  (query?.compare && Array.isArray(query?.compare)
    ? query?.compare[0]
    : query?.compare) as WidgetQueryCompare

export const getCurrentWidgetCompetition = (
  widget: Widget,
  competition: Competition,
  seasons: Season[],
) => {
  const compare = getWidgetCompareQuery(widget?.query)
  const seasonId = compare?.seasonId
  const competitionId = compare?.competitionId
  return seasonId && competitionId
    ? getSeasonAndCompetitionById(seasonId, competitionId, seasons)?.competition
    : competition
}

export const buildWidgetPDFPayload = (widget: Widget, spaceType: WidgetSpaceType): PDFPayload => ({
  selector: `#${SPACE_WIDGET_ID_DOM_SELECTOR_PREFIX}${widget.uuid}`,
  imagePlaceholder: PLACEHOLDER_IMAGES.DEFAULT,
  orientation: widget.displayMode.exportPDFOrientation,
  filename: `${spaceType}-Space__${widget.id}__${new Date().getTime()}`,
  exportType: `${spaceType}-Space__${widget.id}` as any,
  preventImages: widget.displayMode.exportPDFRemoveImages,
})
