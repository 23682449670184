import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'

import {
  ChartComponentsModule,
  ComponentsModule,
  CoreModule,
  DeviceDetectorService,
} from '@mediacoach-ui-library/global'

import { InfiniteScrollModule } from 'ngx-infinite-scroll'

import { McpDialogModule } from './components/dialog/mcp-dialog.module'
import { MorphButtonModule } from './components/morph-button/morph-button.module'

import { LoadingPipe } from './pipes/loading/loading.pipe'
import { AgePipe } from './pipes/age/age.pipe'
import { TemplateDirective } from './directives/template/template.directive'
import { IsStickyDirective } from './directives/is-sticky.directive'
import { InfiniteScrollerDirective } from './directives/infinite-scroller.directive'
import { DownloadExecutableLinkDirective } from './directives/download-executable-link/download-executable-link.directive'

import { ScrollService } from './services/scroll.service'
import { RecaptchaService } from './services/recaptcha.service'
import { RouterEventService } from './services/router-event.service'

import { CardComponent } from './components/card/card.component'
import { ModalComponent } from './components/modal/modal.component'
import { TableComponent } from './components/table/table.component'
import { PlayerStatsComponent } from './components/stats/player-stats.component'
import { InputComponent } from './components/input/input.component'
import { LabelComponent } from './components/label/label.component'
import { TitleComponent } from './components/title/title.component'
import { LoaderComponent } from './components/loader/loader.component'
import { LoadingComponent } from './components/loading/loading.component'
import { HeatMapComponent } from './components/heat-map/heat-map.component'
import { TextareaComponent } from './components/textarea/textarea.component'
import { CarouselComponent } from './components/carousel/carousel.component'
import { ListViewComponent } from './components/list-view/list-view.component'
import { CardBlockComponent } from './components/card-block/card-block.component'
import { MatchCardComponent } from './components/match-card/match-card.component'
import { TeamStatsComponent } from './components/team-stats/team-stats.component'
import { DialogLegalComponent } from './components/dialog-legal/dialog-legal.component'
import { MetricModalComponent } from './components/metric-modal/metric-modal.component'
import { FieldDiagramComponent } from './components/field-diagram/field-diagram.component'
import { IncidenceModalComponent } from './components/incidence-modal/incidence-modal.component'
import { InputContainerComponent } from './components/input-container/input-container.component'
import { RecaptchaButtonComponent } from './components/recaptcha-button/recaptcha-button.component'
import { SegmentedControlComponent } from './components/segmented-control/segmented-control.component'
import { UpdatePasswordModalComponent } from './components/update-password-modal/update-password-modal.component'
import { MatchAssetAvailabilityComponent } from './components/match-asset-availability/match-asset-availability.component'
import { PositionSelectorModalComponent } from './components/position-selector-modal/position-selector-modal.component'
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component'
import { LocalizedHourPipe } from '@shared/pipes/localized-hour/localized-hour.pipe'
import { ExcludePlayerPipe } from '@shared/pipes/exclude-player/exclude-player.pipe'
import { StorageService } from '@shared/services/storage/storage.service'
import { WelcomeComponent } from '@shared/components/welcome/welcome.component'
import { SilentComponent } from '@shared/components/silent/silent.component'
import { ExternalRedirectComponent } from '@shared/components/external-redirect/external-redirect.component'
import { PageComponent } from '@shared/components/page/page.component'
import { DialogApplicationComponent } from '@shared/components/dialog-application/dialog-application.component'
import { CallbackComponent } from '@shared/components/callback/callback.component'
import { ApplicationsComponent } from '@shared/components/applications/applications.component'
import { ErrorFeedbackPageComponent } from '@shared/components/error-feedback-page/error-feedback-page.component'
import { PlayerComparisonComponent } from '@shared/components/player-comparison/player-comparison.component'
import { PlayerChartMetricsComponent } from '@shared/components/player-chart-metrics/player-chart-metrics.component'
import { TranslateModule } from '@ngx-translate/core'
import { PermissionKeyDirective } from '@shared/directives/permission-key.directive'
import { UpdateMatchWithSocketPipe } from '@shared/pipes/update-match-with-socket/update-match-with-socket.pipe'
import { GridComponent } from '@shared/components/grid/grid.component'
import { GridTemplateDirective } from '@shared/components/grid/directives/grid-template.directive'
import { PdfService } from '@shared/services/pdf/pdf.service'
import { LazyImgDirective } from '@shared/directives/lazy-img.directive'
import { DeferLoadModule } from '@shared/modules/defer-load/defer-load.module'
import { DisableDirective } from '@shared/directives/disable.directive'
import { OptionsResolverPipe } from '@shared/components/query/pipes/options-resolver.pipe'
import { QueryComponent } from '@shared/components/query/components/query/query.component'
import { QueryDropdownComponent } from '@shared/components/query/components/query-dropdown/query-dropdown.component'
import { QueriesComponent } from '@shared/components/query/queries.component'
import { UnavailableContentComponent } from './components/unavailable-content/unavailable-content.component'
import { WidgetMetricsComponent } from './components/widget-metrics/widget-metrics.component'
import { QueryTextComponent } from '@shared/components/query/components/query-text/query-text.component'
import { WidgetLastMatchesComponent } from './components/widget-last-matches/widget-last-matches.component'
import { WidgetItemSelectorComponent } from './components/widget-item-selector/widget-item-selector.component'
import { QueryDropdownItemComponent } from '@shared/components/query/components/query-dropdown-item/query-dropdown-item.component'
import { QueryMultiselectItemComponent } from './components/query/components/query-multiselect-item/query-multiselect-item.component'
import { QueryMultiselectComponent } from '@shared/components/query/components/query-multiselect/query-multiselect.component'
import { MatchItemComponent } from '@shared/components/match-item/match-item.component'
import { MatchItemResultTemplateComponent } from '@shared/components/match-item/templates/match-item-result-template/match-item-result-template.component'
import { MatchItemDateTemplateComponent } from '@shared/components/match-item/templates/match-item-date-template/match-item-date-template.component'
import { MatchItemInfoTemplateComponent } from '@shared/components/match-item/templates/match-item-info-template/match-item-info-template.component'
import { MatchItemListComponent } from '@shared/components/match-item-list/components/match-item-list/match-item-list.component'
import { MatchItemListContainerComponent } from '@shared/components/match-item-list/match-item-list-container.component'
import { MatchItemListFooterComponent } from '@shared/components/match-item-list/components/match-item-list-footer/match-item-list-footer.component'
import { MatchItemLimitReachedModalComponent } from './components/match-item-list/components/match-item-limit-reached-modal/match-item-limit-reached-modal.component'
import { MatchItemListFooterWidthDirective } from './components/match-item-list/directive/match-item-list-footer-width.directive'
import { VideoComponent } from '@shared/components/video-container/components/video/video.component'
import { VideoContainerComponent } from '@shared/components/video-container/video-container.component'
import { VideoMarkersComponent } from './components/video-container/components/video-markers/video-markers.component'
import { VideoAdvancedControlsComponent } from '@shared/components/video-container/components/video-advanced-controls/video-advanced-controls.component'
import { AttachMarkersDirective } from '@shared/components/video-container/directives/attach-markers.directive'
import { TooltipHelperDirective } from '@shared/components/video-container/directives/tooltip-helper.directive'
import { CompetitionSelectorComponent } from '@shared/components/competition-selector/competition-selector.component'
import { AllowedRouteNavigationDirective } from '@shared/directives/allowed-route-navigation.directive'
import { CameraSelectorComponent } from '@shared/components/camera-selector/camera-selector.component'
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component'
import { StreamHeaderComponent } from '@shared/components/stream-header/stream-header.component'
import { StreamContentComponent } from './components/stream-content/stream-content.component'
import { ScrollbarBodyHandlerDirective } from '@shared/directives/scrollbar-body-handler/scrollbar-body-handler.directive'
import { TrimPipe } from '@shared/pipes/trim/trim.pipe'
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller'
import { MetricPipe } from '@shared/pipes/metric/metric.pipe'
import { DispatcherGridComponent } from '@shared/components/dispatcher-grid/dispatcher-grid.component'
import { DispatcherCardComponent } from '@shared/components/dispatcher-grid/components/dispatcher-card/dispatcher-card.component'
import { RootFileUploadComponent } from '@shared/components/root-file-upload/root-file-upload.component'
import { WidgetGridOverviewComponent } from '@shared/components/widget-grid-overview/widget-grid-overview.component'
import { CompetitionChangerComponent } from '@shared/components/competition-changer/competition-changer.component'

import { NestedSortableKeyPipe } from '@shared/components/grid/pipes/nested-sortable-key.pipe'
import { ReportIdPipe } from '@shared/pipes/report-id/report-id.pipe'
import { VimeoPlayerDirective } from '@shared/directives/vimeo/vimeo-player.directive'
import { AnalyticsCompanionModule } from '@shared/modules/analytics-companion/analytics-companion.module'
import { TeamLogoComponent } from '@shared/components/team-logo/team-logo.component'
import { IconModule, UiComponentsModule } from '@mediacoach/ui'
import { PlaylistItemInfoComponent } from '@shared/components/playlist-item-info/playlist-item-info.component'
import { ResponsiveCompetitionLogoPipe } from '@shared/pipes/responsive-competition-logo/responsive-competition-logo.pipe'
import { ScoreRelativeToResolverPipe } from '@shared/pipes/score-relative-to-resolver/score-relative-to-resolver.pipe'
import { GridsterModule } from 'angular-gridster2'
import { MultipleTranslatePipe } from '@shared/pipes/multiple-translate/multiple-translate.pipe'
import { DownloadableAssetItemComponent } from '@shared/components/downloadable-block/components/downloadable-asset-item/downloadable-asset-item.component'
import { DownloadableAssetContainerComponent } from '@shared/components/downloadable-block/components/donwloadable-asset-container/downloadable-asset-container.component'
import { DownloadableBlockContainerComponent } from '@shared/components/downloadable-block/downloadable-block-container.component'
import { DemarcationPipe } from '@shared/pipes/demarcation/demarcation.pipe'
import { PlayerListItemComponent } from '@shared/components/player-list-item/player-list-item.component'
import { CompetitionTabsComponent } from '@shared/components/competition-tabs/competition-tabs.component'
import { SpaceSelectorComponent } from '@shared/components/space-selector/space-selector.component'
import { NavigableMatchUrlPipe } from '@shared/pipes/navigable-match-url.pipe'
import { NavigableDirective } from '@shared/directives/navigable.directive'
import { GridsterEmptyItemCalcPipe } from '@shared/pipes/grid-empty-item-calc.pipe'
import { ToTranslateObjectPipe } from '@shared/pipes/to-translate-object/to-translate-object.pipe'
import { RxFor } from '@rx-angular/template/for'
import { TranslateMenuPipe } from '@shared/pipes/translate-menu.pipe'
import { BackgroundImageDirective } from '@shared/directives/background-image.directive'
import { ToRadioOptionPipe } from '@shared/pipes/to-radio-option.pipe'
import { SkeletonElementsModule } from 'skeleton-elements/angular'
import { SeasonCompetitionSelectorComponent } from '@shared/components/dialog-comparison-selector/components/season-competition-selector/season-competition-selector.component'
import { DialogContentContainerComponent } from '@shared/components/dialog-comparison-selector/components/dialog-content-container/dialog-content-container.component'
import { DialogVsSelectorComponent } from '@shared/components/dialog-comparison-selector/components/dialog-vs-selector/dialog-vs-selector.component'
import { DialogSeasonCompetitionSelectorComponent } from '@shared/components/dialog-comparison-selector/components/dialog-season-competition-selector/dialog-season-competition-selector.component'
import { AggregationSelectorDialogComponent } from './components/aggregation-selector-dialog/aggregation-selector-dialog.component'
import { ScrollableItemSelectorComponent } from './components/scrollable-item-selector/scrollable-item-selector.component'
import { NoContentDirective } from '@shared/directives/no-content/no-content.directive'
import { DragNScrollDirective } from '@shared/directives/drag-n-scroll/drag-n-scroll.directive'
import { DragNScrollItemDirective } from '@shared/directives/drag-n-scroll/drag-n-scroll-item.directive'
import { PlatformModule } from '@angular/cdk/platform'

const COMMON_MODULES = [
  PlatformModule,
  ComponentsModule,
  ChartComponentsModule,
  CommonModule,
  InfiniteScrollModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
  CoreModule,
  McpDialogModule,
  MorphButtonModule,
  DeferLoadModule,
  VirtualScrollerModule,
  AnalyticsCompanionModule,
  UiComponentsModule,
  GridsterModule,
  RxFor,
  SkeletonElementsModule,
]
const PIPES = [
  LoadingPipe,
  LocalizedHourPipe,
  ExcludePlayerPipe,
  UpdateMatchWithSocketPipe,
  OptionsResolverPipe,
  TrimPipe,
  MetricPipe,
  AgePipe,
  NestedSortableKeyPipe,
  ReportIdPipe,
  ResponsiveCompetitionLogoPipe,
  ScoreRelativeToResolverPipe,
  MultipleTranslatePipe,
  DemarcationPipe,
  NavigableMatchUrlPipe,
  GridsterEmptyItemCalcPipe,
  ToTranslateObjectPipe,
  TranslateMenuPipe,
  ToRadioOptionPipe,
]
const DIRECTIVES = [
  TemplateDirective,
  IsStickyDirective,
  InfiniteScrollerDirective,
  DownloadExecutableLinkDirective,
  PermissionKeyDirective,
  GridTemplateDirective,
  LazyImgDirective,
  DisableDirective,
  MatchItemListFooterWidthDirective,
  AttachMarkersDirective,
  TooltipHelperDirective,
  AllowedRouteNavigationDirective,
  ScrollbarBodyHandlerDirective,
  VimeoPlayerDirective,
  NavigableDirective,
  BackgroundImageDirective,
  NoContentDirective,
  DragNScrollDirective,
  DragNScrollItemDirective,
]
const COMPONENTS = [
  CardComponent,
  LoadingComponent,
  ModalComponent,
  LoaderComponent,
  MatchAssetAvailabilityComponent,
  ListViewComponent,
  IncidenceModalComponent,
  MatchCardComponent,
  RecaptchaButtonComponent,
  TableComponent,
  DialogLegalComponent,
  CarouselComponent,
  InputContainerComponent,
  TeamStatsComponent,
  CardBlockComponent,
  MetricModalComponent,
  PlayerStatsComponent,
  InputComponent,
  TextareaComponent,
  LabelComponent,
  UpdatePasswordModalComponent,
  SegmentedControlComponent,
  TitleComponent,
  HeatMapComponent,
  FieldDiagramComponent,
  PositionSelectorModalComponent,
  AggregationSelectorDialogComponent,
  RootFileUploadComponent,
  FileUploadComponent,
  UnavailableContentComponent,
  //// PORTAL COMPONENTS
  PageComponent,
  WelcomeComponent,
  SilentComponent,
  ExternalRedirectComponent,
  DialogApplicationComponent,
  CallbackComponent,
  ApplicationsComponent,
  ErrorFeedbackPageComponent,
  PlayerComparisonComponent,
  PlayerChartMetricsComponent,
  // NEW COMPONENTS
  GridComponent,
  QueriesComponent,
  QueryComponent,
  QueryDropdownComponent,
  QueriesComponent,
  WidgetMetricsComponent,
  QueryDropdownItemComponent,
  QueryMultiselectItemComponent,
  QueryMultiselectComponent,
  QueryTextComponent,
  WidgetLastMatchesComponent,
  WidgetItemSelectorComponent,
  MatchItemComponent,
  MatchItemResultTemplateComponent,
  MatchItemDateTemplateComponent,
  MatchItemInfoTemplateComponent,
  MatchItemListContainerComponent,
  MatchItemListComponent,
  MatchItemListFooterComponent,
  MatchItemLimitReachedModalComponent,
  VideoContainerComponent,
  VideoComponent,
  VideoMarkersComponent,
  VideoAdvancedControlsComponent,
  CompetitionSelectorComponent,
  CompetitionSelectorComponent,
  CameraSelectorComponent,
  SidebarComponent,
  StreamHeaderComponent,
  StreamContentComponent,
  DispatcherGridComponent,
  DispatcherCardComponent,
  WidgetGridOverviewComponent,
  CompetitionChangerComponent,
  CompetitionTabsComponent,
  TeamLogoComponent,
  PlaylistItemInfoComponent,
  DownloadableBlockContainerComponent,
  DownloadableAssetContainerComponent,
  DownloadableAssetItemComponent,
  PlayerListItemComponent,
  SpaceSelectorComponent,
  SeasonCompetitionSelectorComponent,
  DialogContentContainerComponent,
  DialogVsSelectorComponent,
  DialogSeasonCompetitionSelectorComponent,
  ScrollableItemSelectorComponent,
]
const PROVIDERS = [
  ScrollService,
  RecaptchaService,
  RouterEventService,
  DeviceDetectorService,
  StorageService,
  PdfService,
]

export const ICON_REGISTRY = {}

@NgModule({
  imports: [...COMMON_MODULES, IconModule.withCustomAssets('/assets/icons', ICON_REGISTRY)],
  declarations: [...PIPES, ...DIRECTIVES, ...COMPONENTS],
  providers: [...PROVIDERS],
  exports: [...COMMON_MODULES, ...PIPES, ...DIRECTIVES, ...COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
