import { createSelector, select } from '@ngrx/store'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { getMergedRouteParams } from '@core/router/state/selectors/router.selectors'
import { Season } from '@core/models/dto/season.dto'
import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { selectSpaceFeature } from './space.selectors'
import { WidgetSpaceType } from '@widgets/models/widget.models'
import { replaceSpaces } from '@core/utils/string.utils'

export const selectSpaceSelectorLoading = createSelector(
  selectSpaceFeature,
  (state) => state.selectorLoading,
)

export const selectSpaceSelectorTeam = createSelector(
  selectSpaceFeature,
  (state) => state.selectorTeam,
)

export const selectSpaceSelectorTeams = createSelector(
  selectSpaceFeature,
  (state) => state.selectorTeams,
)

export const selectSpaceSelectorFilteredTeams = () =>
  pipe(
    select(selectSpaceSelectorTeams),
    filter((teams) => !!teams),
    map((teams) => teams),
  )

const selectParamsAndSeasons = createSelector(
  getMergedRouteParams,
  getSeasons,
  (params: { [key: string]: string }, seasons: Season[]) => ({ params, seasons }),
)

export const selectSpaceSelectorCompetition = createSelector(
  selectSpaceFeature,
  (state) => state.selectorCompetition,
)

export const selectSpaceIsRegularCompetition = createSelector(
  selectSpaceSelectorCompetition,
  (competition) => competition?.competitionType?.toLowerCase() === 'regular',
)

export const selectSpaceSelectorCompetitions = () =>
  pipe(
    select(selectParamsAndSeasons),
    filter(({ params, seasons }) => !!params && !!seasons?.length),
    map(
      ({ params, seasons }) =>
        (seasons.find((s) => s.id === params['seasonId']) || ({} as Season)).competitions,
    ),
  )

export const selectSpaceSelectorTeamIndex = createSelector(
  selectSpaceSelectorTeams,
  selectSpaceSelectorTeam,
  (teams, selectorTeam) => {
    if (teams?.length && selectorTeam) {
      return teams.findIndex((t) => t.id === selectorTeam.id)
    }
  },
)

export const selectSpaceSelectorPlayer = createSelector(
  selectSpaceFeature,
  (state) => state.selectorPlayer,
)

export const selectSpaceSelectorPlayers = createSelector(
  selectSpaceFeature,
  (state) => state.selectorPlayers,
)

export const selectSpaceSelectorFilteredPlayers = () =>
  pipe(
    select(selectSpaceSelectorPlayers),
    filter((players) => !!players),
    map((players) =>
      players.map((p, idx) => ({
        ...p,
        hasDivider:
          idx > 0 &&
          ((p.playerPosition !== players[idx - 1].playerPosition &&
            players[idx - 1].shirtNumber !== 0) ||
            (players[idx - 1].shirtNumber !== 0 && p.shirtNumber === 0)),
      })),
    ),
  )

export const selectSpaceSelectorPlayerIndex = createSelector(
  selectSpaceSelectorPlayers,
  selectSpaceSelectorPlayer,
  (players, selectorPlayer) => {
    if (players?.length && selectorPlayer) {
      return players.findIndex((t) => t.id === selectorPlayer.id)
    }
  },
)

export const selectSpacePDFMetadata = (spaceType: WidgetSpaceType) =>
  createSelector(
    selectSpaceSelectorTeam,
    selectSpaceSelectorPlayer,
    selectSpaceSelectorCompetition,
    getSeasons,
    (team, player, competition, seasons): any => {
      const season = seasons.find((s) => s.competitions.find((c) => c.id === competition.id))

      let str: string
      switch (spaceType) {
        case 'team':
          str = `${team?.shortName}_${competition?.officialName}_${replaceSpaces(season?.officialName, '')}`
          break
        case 'competition':
          str = `${competition?.officialName}_${replaceSpaces(season?.officialName, '')}`
          break
        default:
          str = `${player?.formattedName}_${team?.shortName}_${competition?.officialName}_${replaceSpaces(season?.officialName, '')}`
          break
      }

      return {
        spaceType,
        filename: replaceSpaces(`${str}__${new Date().getTime()}`),
        team: team?.shortName,
        competition: competition?.officialName,
        season: season?.officialName,
        player: player?.formattedName,
      }
    },
  )
